import React, {useEffect, useState} from 'react';
import {Button, InputNumber, Popover, Select, Space, Spin} from 'antd';
import {updateBattleScars} from "../../CardConnector";
import {
    notificationBattleTraitsUpdateSuccess,
    notificationBattleScarsUpdateSuccess,
    notificationFailure
} from "../../CardNotifications";
import {doRestCall} from "../../../../AppUtils";

const BattleTraits = ({forceId, cardId, card}) => {

    const [selectedBattleTraits, setSelectedBattleTraits] = useState([])
    const [loadedBattleTraits, setLoadedBattleTraits] = useState([])
    const [loadedBattleTraitsOptions, setLoadedBattleTraitsOptions] = useState([])

    const [traitsNum, setTraitsNum] = useState(0)

    useEffect(() => {
        if (card !== undefined && card !== null && card.battleTraits !== null && card.battleTraits !== undefined) {
            setTraitsNum(card?.battleTraits?.length)
            setSelectedBattleTraits(card?.battleTraits)
        }
    }, [card])

    useEffect(() => {
        if (card !== undefined &&  card !== null) {
            doRestCall('/codex/crusade/battletraits', 'get', {
                    keywords: card.keywords
                }, null,
                (response) => setLoadedBattleTraits(response.body))
        }
    }, [card])


    useEffect(() => {
        if (loadedBattleTraits !== undefined && loadedBattleTraits !== null) {
            let temp = []
            for (let i = 0; i < loadedBattleTraits.length; i++) {
                temp[i] = {
                    value: loadedBattleTraits[i].key,
                    label: loadedBattleTraits[i].label,
                }
            }
            setLoadedBattleTraitsOptions(temp)
        }

    }, [loadedBattleTraits]);


    const onBattleTraitsUpdate = (index,value) => {
        if (index !== undefined && value !== undefined) {
            if (value === '') {
                selectedBattleTraits.splice(index, 1)
            } else {
                selectedBattleTraits[index] = value
            }
        } else {
            setTraitsNum(0)
            selectedBattleTraits.splice(0, selectedBattleTraits.length)
        }

        doRestCall('/crusade/card/update/battletraits', 'put', null,{
                forceId: forceId,
                cardId: cardId,
                battleTraits: selectedBattleTraits
            },
            () => notificationBattleTraitsUpdateSuccess(),
            () => notificationFailure())
        setSelectedBattleTraits(selectedBattleTraits)
    }

    function removeBattleTrait(index) {
        setTraitsNum(traitsNum - 1);
        onBattleTraitsUpdate(index, '')
    }

    function addBattleTrait(index) {
        setTraitsNum(traitsNum + 1)
    }

    function reset(e) {
        e.preventDefault();
        onBattleTraitsUpdate()
    }

    return <>
        <div className="mb-5">
            <div className="row">
                <div className="col-md-4 text-end"><label className="form-label">Battle Traits</label></div>
                <div className="col-md-6">
                    {traitsNum === 0 && <p>none <i className="fa-solid fa-plus" onClick={() => setTraitsNum(traitsNum + 1)}></i></p>}
                    {Array.from(Array(traitsNum)).map((_, index) => (<div className="col-md-6" style={{width: "100%"}}>
                            <Select
                                placeholder="Select battle trait"
                                defaultValue={selectedBattleTraits && selectedBattleTraits[index]}
                                className=""
                                options={loadedBattleTraitsOptions}
                                onChange={(value) => onBattleTraitsUpdate(index, value)}/>
                            {index+1 === traitsNum && <i className="fa-solid fa-plus" onClick={() => addBattleTrait(index)}></i> }
                            {index+1 === traitsNum && <i className="fa-solid fa-minus" onClick={() => removeBattleTrait(index)}></i>}
                            {index + 1 === traitsNum && <a href="#" onClick={(e) => reset(e)}>Reset</a>}
                        </div>

                    ))}
                </div>
            </div>
        </div>

    </>
}

export default BattleTraits;