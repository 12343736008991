import React, {useEffect, useState} from "react";

import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import {me, update} from "../UserConnector";
import {Auth} from "../auth/Auth";
import {Button, Form, Input, Typography} from "antd";
import {notificationFailure} from "../../roster/RosterNotifications";
import {useNavigate} from "react-router-dom";
import {doRestCall} from "../../AppUtils";
const { TextArea } = Input;

const UserAccount = () => {

    GoogleAnalyticsPageView("/user/account", "User Account")

    const [user, setUser] = useState();

    const [form] = Form.useForm();

    const {Text, Link} = Typography;

    const navigate = useNavigate();

    useEffect(() => {
        doRestCall( '/user/me', 'get', {}, {}, (response) => setUser(response.body))
    }, []);

    useEffect(() => {
        if (user !== undefined) {
            form.setFieldsValue({
                username: user.username,
                email: user.email,
                address: user.location?.address,
                ref: user.ref
            })
        }
    }, [user]);

    function onSuccess(response) {
        if (response.valid) {
            navigate('/home')
        } else {
            notificationFailure(response.errors[0].error)
        }
    }

    function onFinish(values) {
        doRestCall( '/user/update', 'put', null, {
            id: user.id,
            username: values.username,
            password: values.password,
            email: values.email,
            address: values.address
        }, onSuccess)
    }

    return (
        <>
            <Auth/>
            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  style={{width: '100%'}}>

                <Form.Item name="ref" id="ref" hidden="true">
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}>
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}>
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email">
                    <Input/>
                </Form.Item>

                <Form.Item
                label="Address"
                name="address">
                    <TextArea rows={3} maxLength={1000} />
                </Form.Item>

                <p>REF: <span data-cy="ref">{user && user.ref}</span></p>

                <Button type="primary" htmlType="submit">
                    Submit
                </Button>

            </Form>
        </>

    );
};

export default UserAccount;

