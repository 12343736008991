import React, {useEffect, useState} from 'react';
import {Button, InputNumber, Popover, Select, Space, Spin} from 'antd';
import {updateBattleScars} from "../../CardConnector";
import {notificationBattleScarsUpdateSuccess, notificationFailure} from "../../CardNotifications";
import {doRestCall} from "../../../../AppUtils";

const BattleScars = ({forceId, cardId, battleScars}) => {

    const [selectedBattleScars, setSelectedBattleScars] = useState([])
    const [loadedBattleScars, setLoadedBattleScars] = useState([])
    const [loadedBattleScarsOptions, setLoadedBattleScarsOptions] = useState([])

    const [scarsNum, setScarNum] = useState(0)

    useEffect(() => {
        if (battleScars !== undefined &&  battleScars !== null) {
            setScarNum(battleScars.length)
            setSelectedBattleScars(battleScars)
        }
    }, [battleScars])

    useEffect(() => {
        doRestCall('/codex/crusade/battlescars', 'get', null, null,
            (response) => setLoadedBattleScars(response.body))
    }, [])

    useEffect(() => {
        if (loadedBattleScars !== undefined && loadedBattleScars !== null) {
            let temp = []
            for (let i = 0; i < loadedBattleScars.length; i++) {
                temp[i] = {
                    value: loadedBattleScars[i].key,
                    label: loadedBattleScars[i].label,
                }
            }
            setLoadedBattleScarsOptions(temp)
        }

    }, [loadedBattleScars]);


    const onBattleScarsUpdate = (index,value) => {
        if (index !== undefined && value !== undefined) {
            if (value === '') {
                selectedBattleScars.splice(index, 1)
            } else {
                selectedBattleScars[index] = value
            }
        } else {
            setScarNum(0)
            selectedBattleScars.splice(0, selectedBattleScars.length)
        }

        doRestCall('/crusade/card/update/battlescars', 'put', null,{
                forceId: forceId,
                cardId: cardId,
                battleScars: selectedBattleScars
            },
            () => notificationBattleScarsUpdateSuccess(),
            () => notificationFailure())
        setSelectedBattleScars(selectedBattleScars)
    }

    const filterLoadedBattleScarsOptions = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    function removeBattleScar(index) {
        setScarNum(scarsNum - 1);
        onBattleScarsUpdate(index, '')
    }

    function addBattleScar(index) {
        setScarNum(scarsNum + 1)
    }

    function reset(e) {
        e.preventDefault();
        onBattleScarsUpdate()
    }

    return <>
        <div className="mb-5">
            <div className="row">
                <div className="col-md-4 text-end"><label className="form-label">Battle Scars</label></div>
                <div className="col-md-6">
                    {scarsNum === 0 && <p>none <i className="fa-solid fa-plus" onClick={() => setScarNum(scarsNum + 1)}></i></p>}
                    {Array.from(Array(scarsNum)).map((_, index) => (<div className="col-md-6" style={{width: "100%"}}>
                            <Select
                                placeholder="Select battle scar"
                                defaultValue={selectedBattleScars && selectedBattleScars[index]}
                                className=""
                                options={loadedBattleScarsOptions}
/*
                                showSearch
                                filterOption={filterLoadedBattleScarsOptions}
*/
                                onChange={(value) => onBattleScarsUpdate(index, value)}/>
                            {index+1 === scarsNum && <i className="fa-solid fa-plus" onClick={() => addBattleScar(index)}></i> }
                            {index+1 === scarsNum && <i className="fa-solid fa-minus" onClick={() => removeBattleScar(index)}></i>}
                            {index + 1 === scarsNum && <a href="#" onClick={(e) => reset(e)}>Reset</a>}
                        </div>

                    ))}
                </div>
            </div>
        </div>

    </>
}

export default BattleScars;