import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import moment from "moment/moment";

import {  Typography } from 'antd';
import {doRestCall} from "../../../AppUtils";
const { Text, Link } = Typography;


const Games = ({tournamentId, round}) => {

    const navigate = useNavigate();

    const [items, setItems] = useState([])


    useEffect(() => {
        if (round !== null && round !== undefined) {
            doRestCall('/tournament/' + tournamentId + '/round/' + round + '/games', 'get', null, null,
                (response) => {
                    const temp = [];
                    for (let i = 0; i < response?.length; i++) {
                        temp[i] = {
                            key: response[i].key,
                            id: response[i].id,
                            name: response[i].name,
                            player1: response[i].players[0]?.username,
                            player2: response[i].players[1]?.username,
                            date: response[i].dateTime,
                            players: response[i].players,
                            winner: response[i].gameWinnerUsername
                        }
                    }
                    setItems(temp)
                })
        }
    }, [round])

/*
    useEffect(() => {
        const temp = [];
        for (let i = 0; i < games?.length; i++) {
            temp[i] = {
                key: games[i].key,
                id: games[i].id,
                name: games[i].name,
                player1: games[i].players[0]?.username,
                player2: games[i].players[1]?.username,
                date: games[i].dateTime,
                players: games[i].players,
                winner: games[i].gameWinnerUsername
            }
        }
        setItems(temp)
    }, [games]);
*/

    const gamesTableColumns = [{
        title: 'Name', dataIndex: 'name', key: 'key', width: '30%',
        render: (_, row) => <>
            <Link onClick={() => navigate('/game/view/' + row.id)}>
                {row.name}
            </Link>
        </>
    }, {
        title: 'Player1', dataIndex: 'player1', key: 'player1', width: '20%',
    }, {
        title: 'Player2', dataIndex: 'player2', key: 'player2', width: '20%',
    }, {
        title: 'Winner', dataIndex: 'winner', key: 'winner', width: '20%',
    }, {
        title: 'Date', dataIndex: 'date', key: 'date', width: '20%',
        render: (_, row) =>  <p>
            {row.date && moment(row.date, 'YYYY-MM-DDThh:mm:ss').format('DD MMM YYYY')}
        </p>
    },
    ];

    return (
        <>
            <table className="t-v-table3">
                <thead className="t-v-thead3">
                <tr className="t-v-table-row3">
                    <th className="t-v-table-header">Name</th>
                    <th className="t-v-table-header">Player 1</th>
                    <th className="t-v-table-header">Player 2</th>
                    <th className="t-v-table-header">Winner</th>
                    <th className="t-v-table-header">Date</th>
                </tr>
                </thead>
                <tbody className="tbody3">
                {items?.map(item =>
                    <tr className="t-v-table-row3">
                        <td className="t-v-table-cell3 t-v-table-cell-g-name">{item.name}</td>
                        <td className="t-v-table-cell3">{item.player1}</td>
                        <td className="t-v-table-cell3">{item.player2}</td>
                        <td className="t-v-table-cell3">{item.winner}</td>
                        <td className="t-v-table-cell3">{item.date && moment(item.date, 'YYYY-MM-DDThh:mm:ss').format('DD MMM YYYY')}</td>
                    </tr>
                )}
                </tbody>
            </table>

            {/*
            {(games.length > 0) && <Form.Item name="usersFormItem">
                <Table
                    columns={gamesTableColumns}
                    dataSource={items}
                    showHeader={true}
                />
            </Form.Item>
            }
*/}


        </>
    );
};

export default Games;

