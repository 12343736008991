import React, {useEffect, useState} from 'react';
import {Form, List, Popover, Space, Table, Tag, Typography} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import TournamentTags from "./TournamentTags";

const Participants = ({tournamentId, tournamentPlayers}) => {

    const [players, setPlayers] = useState([])
    const [id, setId] = useState()
    const { Text, Link } = Typography;


    useEffect(() => {
        if (tournamentPlayers === undefined || tournamentPlayers === null || tournamentPlayers.length === 0) {
            setPlayers([])
        } else {

            for (let i = 0; i < tournamentPlayers?.length; i++) {
                tournamentPlayers[i] = {
                    id: tournamentPlayers[i].id,
                    name: tournamentPlayers[i].username,
                    position: tournamentPlayers[i].position,
                    bscore: tournamentPlayers[i].stats?.bscore,
                    opponents: tournamentPlayers[i].stats?.opponents,
                    value: tournamentPlayers[i].id,
                    label: tournamentPlayers[i].username,
                    key: tournamentPlayers[i].key,
                    score: tournamentPlayers[i].stats?.score,
                    scoreDetails: tournamentPlayers[i].stats?.scoreDetails,
                    wins: tournamentPlayers[i].stats?.wins,
                    winDetails: tournamentPlayers[i].stats?.winDetails,
                    draws: tournamentPlayers[i].stats?.draws,
                    losses: tournamentPlayers[i].stats?.losses,
                    faction: tournamentPlayers[i].faction
                }
            }

            setPlayers(tournamentPlayers.sort((p1, p2) => p2.score - p1.score))
        }
    }, [tournamentPlayers]);

    const popoverGamesContent = (games) => (
        <>
            <List
                dataSource={games}
                renderItem={(item) => (
                    <List.Item>
                        {item}
                    </List.Item>
                )}
            />
        </>
    );

    const popoverBScoreContent = (score, opponents) => (
        <>
            <Space direction={"vertical"} >
                <Space direction={"horizontal"}>
                    Opponents Score: {score}
                </Space>
                <List
                    dataSource={opponents}
                    renderItem={(item) => (
                        <List.Item>
                            {item}
                        </List.Item>
                    )}
                />
            </Space>
        </>
    );

    const popoverFormulaContent = (formula) => (
        <>
           {formula}
        </>
    );

    const usersTableColumns = [
    {
        title: 'Position', dataIndex: 'position', key: 'position', width: '5%',
        render: (_,row) =>
            <>
                <Space direction={"horizontal"}>
                    {row.position}
                    {row.bscore !== undefined && row.bscore > 0 && <Popover content={popoverBScoreContent(row.bscore, row.opponents)} title="Details">
                        <InfoCircleOutlined  />
                    </Popover>
                    }
                </Space>
            </>
    },
    {
        title: 'Name', dataIndex: 'label', key: 'key', width: '40%',
        render: (_,row) =>
            <>
                <Space direction={"vertical"}>
                    {row.label}
                    <Space>
                        <TournamentTags tournamentId={tournamentId} participantId={row.id}  />
                    </Space>
                </Space>
            </>
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '30%',
    },{
        title: 'Score', dataIndex: 'score', key: 'key', width: '15%',
        render: (_,row) =>
            <>
                <Space direction={"horizontal"}>
                    {row.score}
                    <Popover content={popoverFormulaContent(row.scoreDetails)} title="Score Formula">
                        <InfoCircleOutlined  />
                    </Popover>
                </Space>
            </>
    },{
        title: 'Wins', dataIndex: 'wins', key: 'key', width: '5%',
        render: (_,row) =>
            <>
                <Space direction={"horizontal"}>
                    {row.wins}
                    {row.wins > 0 && <Popover content={popoverGamesContent(row.winDetails)} title="Win Games">
                        <InfoCircleOutlined />
                    </Popover>}
                </Space>
            </>
    },{
        title: 'Draws', dataIndex: 'draws', key: 'key', width: '5%',
    },{
        title: 'Losses', dataIndex: 'losses', key: 'key', width: '5%',
    }];

    return <>
{/*
        {(players.length === 0) &&
            <>
                <h2 className="tournTableInfoTitles">Participants:</h2>
                <div className="tournViewNoTable"> No Participants Found</div>
            </>
        }
*/}

        <div className="t-v-vtable1-main">
            <div className="t-v-vtable-container">
                <h2 className="t-v-vtable-title2">Participants</h2>
                <table className="t-v-vtable">
                    <thead className="t-v-vtable-head">
                    <tr className="t-v-vtable-row">
                        <th className="t-v-vtable-header">Position</th>
                        <th className="t-v-vtable-header">Players</th>
                        <th className="t-v-vtable-header">Faction</th>
                        <th className="t-v-vtable-header">Score</th>
                        <th className="t-v-vtable-header">Win</th>
                        <th className="t-v-vtable-header">Draw</th>
                        <th className="t-v-vtable-header">Loss</th>
                    </tr>
                    </thead>
                    <tbody className="t-v-vtable-body">
                    {players.map(player =>
                        <tr className="t-v-vtable-row">
                            <td className="t-v-vtable-cell">{player.position}</td>
                            <td className="t-v-vtable-cell t-v-table-cell-p-name">
                                {player.name}
{/*
                                <div className="div-voting-tags">
                                    <span className="span-voting-tag">
                                        <span className="tag">Voting tag1</span>
                                        <span className="tag_badge">111</span>
                                    </span>
                                    <i style={{color: "#d71f26"}} className=" fa-solid fa-thumbs-down"></i>
                                </div>
*/}
                            </td>
                            <td className="t-v-vtable-cell">{player.faction}</td>
                            <td className="t-v-vtable-cell">{player.score}</td>
                            <td className="t-v-vtable-cell">{player.wins}</td>
                            <td className="t-v-vtable-cell">{player.draws}</td>
                            <td className="t-v-vtable-cell loss">{player.losses}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>

        {/*
        { (players.length > 0) && <Form.Item name="usersFormItem">
            <h2 className="tournTableInfoTitles">Participants:</h2>
            <div className="tournViewTable">
                <Table
                    onRow={(record, rowIndex) => {
                        return {
                            onMouseEnter: (event) => {
                                setId(record.id)
                            },
                            onMouseLeave: (event) => {
                                setId(undefined)
                            },
                        };
                    }}
                    columns={usersTableColumns}
                    dataSource={players}
                    pagination={
                        {
                            pageSize: 10
                        }
                    }
                    id={"usersTable"}
                    style={{width: '100%', minWidth: '42rem'}}
                    showHeader={true}
                />
            </div>
        </Form.Item>
        }
*/}

    </>
}

export default Participants;