import React, {useEffect, useState} from "react";
import "../styles-h.css";

import DefaultNewsItem from "./DefaultNewsItem";
import {doRestCall} from "../../AppUtils";
import Spinner from "../../_components/Spinner";
import Pagination from "../components/Pagination";

const News = ({startDate, endDate}) => {
    const [loading, setLoading] = useState(true)
    const [news, setNews] = useState([]);

    const [selectedPage, setSelectedPage] = useState(1)
    const [pagination, setPagination] = useState({})

    useEffect(() => {
        setSelectedPage(1)
        load()
    }, [startDate, endDate])

    useEffect(() => {
        load()
    }, [selectedPage])

    const load = () => {

        doRestCall('/news', 'get', {
                startDate: startDate,
                endDate: endDate,
                currentPage: selectedPage
            },
            null,
            (response) => {
                setNews(response.body)
                setPagination(response.pagination)
                setLoading(false)
            })
    }

    return (
        <>
            <Spinner isLoading={loading}>
                <div className="h-grid-2">
                    {news?.map(item => <DefaultNewsItem
                        imageUrl={item.imageUrl}
                        url={item.url}
                        title={item.title}
                        publishedDate={item.publishedDate}
                    />)}
                    {/*
                <List
                    itemLayout="vertical"
                    bordered={false}
                    pagination={false}
                    dataSource={news}
                    renderItem={(item) => (
                        <List.Item id="newsListItem">

                            {item.source === 'SMARTROSTER' ?
                                <SmartRosterNewsItem
                                    imageUrl={item.imageUrl}
                                    url={item.url}
                                    title={item.title}
                                    body={item.body}
                                    publishedDate={item.publishedDate}
                                /> : <DefaultNewsItem
                                    imageUrl={item.imageUrl}
                                    url={item.url}
                                    title={item.title}
                                    publishedDate={item.publishedDate}
                                />}

                        </List.Item>
                    )}
                />
*/}

                </div>
                <Pagination pagination={pagination} selectedPage={setSelectedPage} />

            </Spinner>
        </>
    );
};

export default News;