import React, {useEffect, useState} from "react";
import {Menu} from "antd";
import {useNavigate} from "react-router-dom";
import {isAnonymous, isUserAuthenticated} from "../user/auth/Auth";
import {useCurrentUserContext} from "../user/CurrentUserContext";
import logo from "../_components/images/logo1200_processed.png";

import "./styles-menu.css"

const AnonymousUserMainMenu = () => {
    const navigate = useNavigate();

    const [current, setCurrent] = useState('home');
    const {currentUser} = useCurrentUserContext();


    return (
        <>
            {isAnonymous(currentUser) &&
                < nav className="m-navbar">
                    <div ><img  className="m-logo" src={logo} alt=""/></div>
                    <ul className="m-menu">
{/*
                        <li><a href="#">Features</a></li>
                        <li><a href="#about">FAQ</a></li>
                        <li><a href="#">Contact</a></li>
*/}
                    </ul>
                    <div className="m-user-info">
                        <button onClick={() => navigate('/user/login')}
                                className="m-button m-button-signin">Sign In</button>
                        <button onClick={() => navigate('/user/registration')}
                                className="m-button m-button-join-the-fight">Join the Fight</button>
                    </div>
                </nav>
            }
        </>
    )
}

export default AnonymousUserMainMenu;

