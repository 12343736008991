import React, {useEffect, useState} from 'react';
import {Form, Select, Spin, Steps} from 'antd';

const Status = ({status}) => {

    const [current, setCurrent] = useState();

    const [created, setCreated] = useState(false)
    const [regOpened, setRegOpened] = useState(false)
    const [regClosed, setRegClosed] = useState(false)
    const [started, setStarted] = useState(false)
    const [ended, setEnded] = useState(false)


    useEffect(() => {
        switch (status) {
            case 'CREATED':
                setCreated(true);
                break;
            case 'REGISTRATION_OPENED':
                setCreated(true);
                setRegOpened(true);
                break;
            case 'REGISTRATION_CLOSED':
                setCreated(true);
                setRegOpened(true);
                setRegClosed(true);
                break;
            case 'STARTED':
                setCreated(true);
                setRegOpened(true);
                setRegClosed(true);
                setStarted(true);
                break;
            case 'ENDED':
                setCreated(true);
                setRegOpened(true);
                setRegClosed(true);
                setStarted(true);
                setEnded(true);
                break;
        }
    }, [status]);

    return <>
        <div className="t-v-status-ch">
            {created && <div><span className="t-v-status-check">&#10003;</span> Created</div>}
            {!created && <div><span className="t-v-status-square"></span> Created</div>}
            {regOpened  &&
                <div><span className="t-v-status-check">&#10003;</span> Registration Open</div>}
            {!regOpened && <div><span className="t-v-status-square"></span> Registration Close</div>}
            {regClosed &&
                <div><span className="t-v-status-check">&#10003;</span> Registration Close</div>}
            {!regClosed && <div><span className="t-v-status-square"></span> Registration Close</div>}
            {started && <div><span className="t-v-status-check">&#10003;</span> Started</div>}
            {!started && <div><span className="t-v-status-square"></span> Started</div>}
            {ended && <div><span className="t-v-status-check">&#10003;</span> Ended</div>}
            {!ended && <div><span className="t-v-status-square"></span> Ended</div>}
        </div>
    </>
}

export default Status;