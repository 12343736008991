import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import "./c-r-manage-styles.css"
import Card from "./components/Card";
import {doRestCall} from "../../../AppUtils";

const CrusadeRosterManage = () => {

    const navigate = useNavigate();

    let {forceId, rosterId} = useParams();

    const [roster, setRoster] = useState({})
    const [summary, setSummary] = useState(0)


    useEffect(() => {
        if (rosterId !== undefined && rosterId !== null) {
            doRestCall('/crusade/force/' + forceId + '/roster/' + rosterId, 'get', null, null,
                (response) => {
                    setRoster(response.body)
                    setSummary(response.body.summary)
                })
        }
    }, [rosterId])

    function navigateBack(e) {
        e.preventDefault()
        navigate('/crusade/force/' + forceId + '/roster/' + rosterId + '/view')
    }

    return (
        <>
                <div className="c-f-view-section mt-5">
                    <div className="c-r-manage-roster-container">
                        <div className="c-f-view-header mb-5">
                            <h2>{roster.name}</h2>
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-10">
                                <div className="row d-flex justify-content-between">
                                    <div className="col-auto">
                                    </div>
                                    <div className="col-auto">
                                        <p>Summary {summary}</p>
                                    </div>
                                </div>
                                <div className="form mb-5 ">
                                    <div className="row mb-5 justify-content-center">
                                        <div className="col-sm-4">
                                            <h4>Epic Hero</h4>
                                            {roster?.forceCards?.epicHeroCards
                                                .map(card => <Card forceId={forceId} rosterId={rosterId} card={card} setSummary={setSummary} />)}
                                        </div>
                                        <div className="col-sm-4">
                                            <h4>Character</h4>
                                            {roster?.forceCards?.characterCards
                                                .map(card => <Card forceId={forceId} rosterId={rosterId} card={card} setSummary={setSummary} />)}
                                        </div>
                                    </div>
                                    <div className="row mb-5 justify-content-center">
                                        <div className="col-sm-4">
                                            <h4>Battleline</h4>
                                            {roster?.forceCards?.battlelineCards
                                                .map(card => <Card forceId={forceId} rosterId={rosterId} card={card} setSummary={setSummary} />)}
                                        </div>
                                        <div className="col-sm-4">
                                            <h4>Other</h4>
                                            {roster?.forceCards?.otherCards
                                                .map(card => <Card forceId={forceId} rosterId={rosterId} card={card} setSummary={setSummary} />)}
                                        </div>
                                    </div>

                                </div>

                                <div className="row d-flex justify-content-between">
                                    <div className="col-auto">
                                        <div className="c-f-view-tlist-btn" onClick={(e) => navigateBack(e)}>Back</div>
                                    </div>
                                    <div className="col-auto">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </>
    );
};

export default CrusadeRosterManage;

