import React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Affix, Typography} from "antd";
import HelpButton from "./HelpButton";

import {useCurrentUserContext} from "../../user/CurrentUserContext";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";

import "./styles-footer.css"

const { Text } = Typography;

const Footer = ({queryParams}) => {
    const navigate = useNavigate();
    const { currentUser } = useCurrentUserContext();
    const [searchParams, setSearchParams] = useSearchParams();

    function openLink(link) {
        window.open(link, '_blank').focus();
    }

    return (
        <>
            {searchParams.get('print') !== null && <div className="print-footer">https://SmartRoster.club</div>}
            {searchParams.get('print') === null &&
                <>
                    <HelpButton/>

                    <footer className="footer">
                        <div className="footer-content">
                            <div className="footer-links">
                                <div className="footer-section">
                                    <h4>Support</h4>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        GoogleAnalyticsPageView("Footer: Patreon", "Footer: Patreon")
                                        openLink('https://patreon.com/SmartRoster')
                                    }}> Patreon</a>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        GoogleAnalyticsPageView("Footer: GoFund", "Footer: GoFund")
                                        openLink('https://gofund.me/e0aa6bc5')
                                    }}> GoFundMe</a>
                                </div>
                                <div className="footer-section">
                                    <h4>Community</h4>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        GoogleAnalyticsPageView("Footer: Reddit", "Footer: Reddit")
                                        openLink('https://www.reddit.com/r/SmartRoster')
                                    }}>Reddit</a>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        GoogleAnalyticsPageView("Footer: Facebook", "Footer: Facebook")
                                        openLink('https://www.facebook.com/groups/824327146381514')
                                    }}>Facebook</a>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        GoogleAnalyticsPageView("Footer: VK", "Footer: VK")
                                        openLink('https://vk.com/smartroster')
                                    }}>VK</a>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        GoogleAnalyticsPageView("Footer: Discord", "Footer: Discord")
                                        openLink('https://discord.gg/hEUbeTvhZn')
                                    }}>Discord</a>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        GoogleAnalyticsPageView("Footer: Telegram", "Footer: Telegram")
                                        openLink('https://t.me/+xMY6OU7d0JFlZTFh')
                                    }}>Telegram</a>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        GoogleAnalyticsPageView("Footer: Twitch", "Footer: Twitch")
                                        openLink('https://www.twitch.tv/smartroster')
                                    }}>Twitch</a>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        GoogleAnalyticsPageView("Footer: Email", "Footer: Email")
                                        openLink('mailto:alex@smartroster.club')
                                    }}>Email</a>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <p><span style={{color: "#d32f2f"}}> SmartRoster 2025</span></p>
                        </div>
                    </footer>
                </>
                /*
                                </Affix>
                */
            }
        </>
    );
};

export default Footer;

