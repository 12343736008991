import React, {useEffect, useState} from "react";
import {Auth} from "../../../user/auth/Auth";

import "./c-r-print-styles.css"
import {useNavigate, useParams} from "react-router-dom";
import Card from "../view/components/Card";
import {doRestCall} from "../../../AppUtils";
import CardsPrint from "./components/CardsPrint";

const CrusadeRosterPrint = () => {

    let {forceId,rosterId} = useParams();

    const [roster, setRoster] = useState({})
    const [summary, setSummary] = useState(0)

    useEffect(() => {
        if (rosterId !== undefined && rosterId !== null) {
            doRestCall("/crusade/force/" + forceId + "/roster/" + rosterId, "get", null, null,
                (response) => {
                    setRoster(response.body)
                    setSummary(response.body.summary)
                })
        }
    }, [rosterId])

    return (
        <>
            <Auth/>

            <div className="c-f-view-section mt-5">
                <div className="c-r-manage-roster-container">
                    <div className="c-f-view-header mb-5">
                        <h2>{roster.name}</h2>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <div className="row d-flex justify-content-between">
                                <div className="col-auto">
                                </div>
                                <div className="col-auto">
                                    <p>Summary {summary}</p>
                                </div>
                            </div>

                            <CardsPrint cards={roster?.forceCards?.epicHeroCards?.filter(card => card.selected)} />
                            <CardsPrint cards={roster?.forceCards?.characterCards?.filter(card => card.selected)} />
                            <CardsPrint cards={roster?.forceCards?.battlelineCards?.filter(card => card.selected)} />
                            <CardsPrint cards={roster?.forceCards?.otherCards?.filter(card => card.selected)} />

                            {/*
                            <CardsPrint cards={roster?.forceCards?.battlelineCards?.filter(card => card.selected)} />
                            <CardsPrint cards={roster?.forceCards?.otherCards?.filter(card => card.selected)} />
*/}
{/*
                            <div className="form mb-5 ">
                                <div className="row mb-5 justify-content-center">
                                    <div className="col-sm-4">
                                        <h4>Epic Hero</h4>
                                        {roster?.forceCards?.epicHeroCards
                                            .filter(card => card.selected)
                                            .map(card => <Card card={card}/>)}
                                    </div>
                                    <div className="col-sm-4">
                                        <h4>Character</h4>
                                        {roster?.forceCards?.characterCards
                                            .filter(card => card.selected)
                                            .map(card => <Card card={card}/>)}
                                    </div>
                                </div>
                                <div className="row mb-5 justify-content-center">
                                    <div className="col-sm-4">
                                        <h4>Battleline</h4>
                                        {roster?.forceCards?.battlelineCards
                                            .filter(card => card.selected)
                                            .map(card => <Card card={card}/>)}
                                    </div>
                                    <div className="col-sm-4">
                                        <h4>Other</h4>
                                        {roster?.forceCards?.otherCards
                                            .filter(card => card.selected)
                                            .map(card => <Card card={card}/>)}
                                    </div>
                                </div>

                            </div>
*/}

                        </div>
                    </div>
                </div>
            </div>

        </>
    );


};

export default CrusadeRosterPrint;

