import React, {useEffect, useState} from "react";
import {Cascader, InputNumber, Space, Table, Tag, Checkbox, Select, Divider, Typography, Button, Dropdown} from 'antd';
import UnitMultipleModels from "./UnitMultipleModels";
import UnitSingleModel from "./UnitSingleModels";

const {Text, Link} = Typography;

const Unit = ({unit, onUnitsChange}) => {

    const [hideCustomize, setHideCustomize] = useState(true);

    return (
        <div onMouseOver={event => setHideCustomize(false)}
             onMouseLeave={event => setHideCustomize(true)}>

            { unit.models.length > 1 && <UnitMultipleModels unit={unit} onUnitsChange={onUnitsChange} hideCustomize={hideCustomize} /> }
            { unit.models.length === 1 && <UnitSingleModel unit={unit} onUnitsChange={onUnitsChange} hideCustomize={hideCustomize}/> }

        </div>
    );

};

export default Unit;













