import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Modal, Space} from "antd";
import {doRestCall} from "../../../../AppUtils";

const Rosters = ({forceId}) => {

    const navigate = useNavigate();

    const [rosters, setRosters] = useState([])

    useEffect(() => {
        if (forceId !== undefined && forceId !== null) {
            doRestCall('/crusade/force/' + forceId + '/rosters/all', 'get', null, null,
                (response) => setRosters(response.body.sort((a,b) => b.createdAt - a.createdAt)))
        }
    }, [forceId])

    function view(forceId, rosterId) {
        navigate('/crusade/force/' + forceId + '/roster/' + rosterId + '/view')
    }

    return <>
        <div className="mb-3">
            <h4>Rosters</h4>
        </div>
        <div className="mb-3">
            <table className="c-f-view-vtable">
                <thead className="c-f-view-vtable-head">
                <tr className="c-f-view-vtable-row">
                    <th className="c-f-view-vtable-header">Name</th>
                </tr>
                </thead>
                <tbody className="c-f-view-vtable-body">
                {rosters?.map(roster =>
                    <tr className="c-f-view-vtable-row">
                        <td className="c-f-view-vtable-cell c-f-view-vtable-cell-name"><a
                            onClick={() => view(forceId, roster.id)}>{roster.name}</a>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    </>
}

export default Rosters;