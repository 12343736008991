import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../../AppUtils";


const WarlordButton = ({forceId, rosterId, cardId, canBeWarlord, warlord, setWarlord}) => {

    function clicked(e) {
        e.preventDefault()

        if (warlord) {
            doRestCall('/crusade/force/' + forceId + '/roster/' + rosterId + '/card/' + cardId + '/warlord/remove',
                'put', null, null, (response) => setWarlord(response.body.warlord))
        }

        if (!warlord) {
            doRestCall('/crusade/force/' + forceId + '/roster/' + rosterId + '/card/' + cardId + '/warlord/add',
                'put', null, null, (response) => setWarlord(response.body.warlord))
        }
    }

    return (
        <>
            {canBeWarlord && <button className="c-r-manage-warlord-button" type="link" onClick={(e) => clicked(e)}>Warlord</button> }
        </>
    );
};

export default WarlordButton;

