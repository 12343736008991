import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";

const DeleteButton = ({forceId, ownerId}) => {

    const {currentUser} = useCurrentUserContext();
    const navigate = useNavigate();

    const onManage = (e) => {
        e.preventDefault()
        navigate('/crusade/force/' + forceId + '/manage')
    };

    return <>
        {(currentUser?.role === 'ADMIN' || currentUser?.id === ownerId)
            && <button data-cy="manage" className="c-f-view-tlist-btn" onClick={(e) => onManage(e)}>Manage</button>}
    </>
}

export default DeleteButton;