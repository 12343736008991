import React from "react";
import "../styles-h.css";
import DateRange from "./DateRange";


const Menu = ({updateStartDate, updateEndDate}) => {


    return (
        <>
            <div className="h-navbar-cards">
{/*
                <ul>
                    <li className="active">Overview</li>
                    <li>Games</li>
                    <li>Events</li>
                    <li>Others</li>
                </ul>
*/}
                <DateRange updateStartDate={updateStartDate} updateEndDate={updateEndDate} />
            </div>

        </>
    );
};

export default Menu;