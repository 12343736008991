import React, {useEffect, useState} from 'react';
import { Space, Typography } from 'antd';
const { Text, Link } = Typography;

const Status = ({status}) => {

    const [title, setTitle] = useState();

    useEffect(() => {
        switch (status) {
            case 'CREATED': setTitle('Created'); break;
            case 'REGISTRATION_OPENED': setTitle('Registration Open'); break;
            case 'REGISTRATION_CLOSED': setTitle('Registration Close'); break;
            case 'STARTED': setTitle('In Progress'); break;
            case 'ENDED': setTitle('Finished'); break;
        }
    }, [status]);

    return <>
        <Text code>{title}</Text>
    </>
}

export default Status;