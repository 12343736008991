import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Games from "./Games";
import {doRestCall} from "../../../AppUtils";

const Rounds = ({tournamentId }) => {

    const navigate = useNavigate();

    const [roundItems, setRoundItems] = useState([]);

    const [rounds, setRounds] = useState([0])
    const [selectedRound, setSelectedRound] = useState(0)

    useEffect(() => {
        doRestCall('/tournament/' + tournamentId + '/rounds', 'get', null, null,
            (response) => {
                setRounds(Array.from({length: response.body.rounds}, (_, i) => i + 1).reverse())
            })
    }, [])

    useEffect(() => {
        if (rounds !== undefined && rounds !== null && rounds.length > 0) {
            setSelectedRound(Math.max(...rounds))
        }
    }, [rounds])

    return <>

    <div className="t-v-table2-ch-main">
        <div className="t-v-container3">
            <h2 className="t-v-table-title2">Rounds</h2>

            <div className="t-v-rounds3">
                <div className="t-v-round3-iner">
                    {rounds?.map(round =>
                        <button className={selectedRound === round ? "t-v-round-btn3 t-v-active3" : "t-v-round-btn3 " }
                        onClick={() => setSelectedRound(round)}>Round {round}</button>
                    )}
                </div>
            </div>

            <Games tournamentId={tournamentId} round={selectedRound} />

        </div>

    </div>


        {/*
        <h2 className="tournTableInfoTitles">Rounds:</h2>
        <div className="tournViewTable">
            <Tabs
                defaultActiveKey={rounds?.length}
                items={roundItems}
            />
        </div>
*/
        }
    </>
}

export default Rounds;