import React, {useEffect, useState} from "react";
import {
    Button, Popconfirm,
    Space,
} from "antd";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import ChangeHistory from "./components/ChangeHistory";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import {doRestCall} from "../../AppUtils";

import "./styles-g-view.css"

const GameView = () => {

    GoogleAnalyticsPageView("/game/view", "Game View")

    const navigate = useNavigate();

    let {gameId} = useParams();

    const {currentUser} = useCurrentUserContext();

    const [loading, setLoading] = useState(true)
    const [game, setGame] = useState({});

    useEffect(() => {
        load()
    }, [])

    function navigateBack() {
        navigate("/games")
    }

    function onGameViewSuccess(response) {
        setGame(response.body)
        setLoading(false)
    }

    const load = () => {
        doRestCall('/game/view/' + gameId, 'get', null, null, onGameViewSuccess)
    }

    const onEdit = () => {
        navigate("/game/edit/" + gameId)
    };

    function onSuccess() {
        navigate('/games')
    }

    function onConfirmDelete() {
        doRestCall('/game/delete/' + gameId, 'delete', null, null, onSuccess)
    }

    return (
        <>
            <Auth/>

            {loading && <p>loading</p>}
            {!loading &&
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-5">
                        <div className="g-v-game-details">
                            <div className="mb-5">
                                <h2>Game Details</h2>
                            </div>
                            <div className="mb-3 game-details-container">
                                <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                                    <div className="col-auto">
                                        <div className="g-v-gd-bold">Name</div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="g-v-gd-info">{game.name}</div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between mb-3 text-larger">
                                    <div className="col-auto">
                                        <div className="g-v-gd-bold">Universe</div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="g-v-gd-info">{game.universe}</div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                                    <div className="col-auto">
                                        <div className="g-v-gd-bold">Date</div>
                                    </div>
                                    <div className="col-auto">
                                        <div
                                            className="g-v-gd-info">{game.dateTime && moment(game.dateTime, 'YYYYMMDD').format('DD MMM YYYY')}</div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                                    <div className="col-auto">
                                        <div className="g-v-gd-bold">Game Master</div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="g-v-gd-info">{game.gameMasterUsername}</div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                                    <div className="col-auto">
                                        <div className="g-v-gd-bold">Notes</div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="g-v-gd-info">{game.notes}</div>
                                    </div>
                                </div>

                                {game.players && game.players.map((player) =>
                                    <>
                                        <hr className="g-v-custom-hr"/>
                                        <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                                            <div className="col-auto">
                                                <div className="g-v-gd-bold">Player Name</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="g-v-gd-info">{player.username}</div>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                                            <div className="col-auto">
                                                <div className="g-v-gd-bold">Faction</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="g-v-gd-info">{player.faction}</div>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                                            <div className="col-auto">
                                                <div className="g-v-gd-bold">Score</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="g-v-gd-info">{player.score}</div>
                                            </div>
                                        </div>
                                    </>)
                                }

                                <hr className="g-v-custom-hr"/>
                                <div className="row d-flex justify-content-between mb-3 g-v-text-larger">
                                    <div className="col-auto">
                                        <div className="g-v-gd-bold">Winner</div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="g-v-gd-info">{game.gameWinnerUsername}</div>
                                    </div>
                                </div>

                                <div className="row mb-3 justify-content-between g-v-buttons-container">
                                    <div className="col-auto">
                                        <button className="g-v-tlist-btn" onClick={navigateBack}>Back</button>
                                    </div>
                                    <div className="col-auto">
                                        {(currentUser?.role === 'ADMIN' || currentUser?.id === game.ownerId || game.gameMasterId)
                                            && <Space direction={"horizontal"}>
                                                <button className="g-v-tlist-btn" onClick={onEdit}>Edit</button>
                                                <span className="g-v-popconfirm">
                                                <Popconfirm
                                                            title={"Delete game"}
                                                            description={"Are you sure you want to delete this game?"}
                                                            onConfirm={onConfirmDelete}>
                                                    <button className="g-v-tlist-btn-active">Delete</button>
                                                </Popconfirm>
                                                    </span>
                                            </Space>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default GameView;

