import React, {useEffect, useState} from 'react';
import {Divider, Form, Select, Space, Spin, Steps} from 'antd';
import moment from "moment";
import Registration from "./Registration";
import Factions from "./Factions";
import Spinner from "../../../_components/Spinner";
import DetailsRegistrationOpen from "./DetailsRegistrationOpen";
import DetailsRegistrationAlreadyRegistered from "./DetailsRegistrationAlreadyRegistered";
import {doRestCall} from "../../../AppUtils";

const Details = ({
                     status,
                     regStartDate,
                     regEndDate,
                     startDate,
                     endDate,
                     master,
                     universe,
                     tournamentId,
                     onTournamentUpdate,
                     participantsLimit,
                     participantsCount,
                     registrationOpen
                 }) => {
    const [tStartDateColor, setTStartDateColor] = useState('black');
    const [tEndDateColor, setTEndDateColor] = useState('black');

    const [tRegStartDateColor, setTRegStartDateColor] = useState('black');
    const [tRegEndDateColor, setTRegEndDateColor] = useState('black');

    const [tMasterColor, setTMasterColor] = useState('black');

    const [tParticipantsLimitColor, setTParticipantsLimitColor] = useState('black');

    const [tUniverseColor, setTUniverseColor] = useState('black');

    const [tStartDate, setTStartDate] = useState(startDate);
    const [tEndDate, setTEndDate] = useState(startDate);

    const [tRegStartDate, setTRegStartDate] = useState(regStartDate);
    const [tRegEndDate, setTRegEndDate] = useState(regEndDate);

    const [tMaster, setTMaster] = useState(master?.username);

    const [tUniverse, setTUniverse] = useState(universe);

    const [tParticipantsLimit, setTParticipantsLimit] = useState(participantsLimit);

    const [isAlreadyRegistered, setAlreadyRegistered] = useState(false)

    useEffect(() => {
        switch (status) {
            case 'REGISTRATION_CLOSED':
            case 'STARTED':
            case 'ENDED':
                setTStartDateColor("gray")
                setTEndDateColor("gray")
        }
    }, [status])

    useEffect(() => {
        doRestCall('/tournament/isregistered/' + tournamentId, 'get', null, null,
            (response) => setAlreadyRegistered(response.body === 'REGISTERED'))
    }, [])

    useEffect(() => {
        if (regStartDate === undefined || regStartDate === null) {
            setTRegStartDate("<not set>")
            setTRegStartDateColor("gray")
        } else {
            setTRegStartDate(moment(regStartDate, 'YYYYMMDD').format('DD MMM YYYY'))
            setTRegStartDateColor("black")
        }

        if (regEndDate === undefined || regEndDate === null) {
            setTRegEndDate("<not set>")
            setTRegEndDateColor("gray")
        } else {
            setTRegEndDate(moment(regEndDate, 'YYYYMMDD').format('DD MMM YYYY'))
            setTRegEndDateColor("black")
        }

        if (startDate === undefined || startDate === null) {
            setTStartDate("<not set>")
            setTStartDateColor("gray")
        } else {
            setTStartDate(moment(startDate, 'YYYYMMDD').format('DD MMM YYYY'))
            setTStartDateColor("black")
        }

        if (endDate === undefined || endDate === null) {
            setTEndDate("<not set>")
            setTEndDateColor("gray")
        } else {
            setTEndDate(moment(endDate, 'YYYYMMDD').format('DD MMM YYYY'))
            setTEndDateColor("black")
        }

        if (master === undefined || master === null) {
            setTMaster("<not set>")
            setTMasterColor("gray")
        } else {
            setTMaster(master.username)
            setTMasterColor("black")
        }

        if (participantsLimit === undefined || participantsLimit === null || participantsLimit === 0) {
            setTParticipantsLimit("<no limit>")
            setTParticipantsLimitColor("gray")
        } else {
            setTParticipantsLimit(participantsLimit)
            setTParticipantsLimitColor("black")
        }

        if (universe === undefined || universe === null) {
            setTUniverse("<not set>")
            setTUniverseColor("gray")
        } else {
            setTUniverse(universe)
            setTUniverseColor("black")
        }

    }, [regStartDate, regEndDate, startDate, endDate, master])

    return <>
        <div className="t-v-details-ch">
            <div className="t-v-date-info-ch t-v-line1">
                <p><strong className="t-v-str">Start Date:</strong> {tStartDate}</p>
                <p><strong className="t-v-str">End Date:</strong> {tEndDate}</p>
            </div>
            <div className="t-v-date-info-ch">
                <p><strong className="t-v-str">Registration Start Date:</strong> {tRegStartDate}</p>
                <p><strong className="t-v-str">Registration End Date:</strong> {tRegEndDate}</p>
            </div>
            {registrationOpen && !isAlreadyRegistered && <DetailsRegistrationOpen
                onTournamentUpdate={onTournamentUpdate}
                universe={universe}
                tournamentId={tournamentId}
                setAlreadyRegistered={setAlreadyRegistered}/>}
            {registrationOpen && isAlreadyRegistered && <DetailsRegistrationAlreadyRegistered
                onTournamentUpdate={onTournamentUpdate}
                setAlreadyRegistered={setAlreadyRegistered}
                tournamentId={tournamentId}/>}
        </div>
        <div className="t-v-details-ch2">
            <p><strong className="t-v-str">Universe:</strong> {tUniverse}</p>
            <p><strong className="t-v-str">Master:</strong> {tMaster}</p>
            <p><strong className="t-v-str">Participation Limit:</strong> {tParticipantsLimit}</p>
        </div>

    </>
}

export default Details;