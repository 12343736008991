import React, {useEffect, useState} from "react";


const WarlordMark = ({warlord}) => {

    const [showWarlordMark, setShowWarlordMark] = useState(false)

    useEffect(() => {
        setShowWarlordMark(warlord)
    }, [warlord])

    return (
        <>
            {showWarlordMark && <label className="c-r-manage-warlord-mark">W</label> }
        </>
    );
};

export default WarlordMark;

