import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Modal, Space} from "antd";
import {doRestCall} from "../../../../AppUtils";

const Cards = ({force}) => {

    return <>
        <div className="mb-3">
            <h4>Cards</h4>
        </div>
        <div className="mb-3 ">
            <table className="c-f-view-vtable">
                <thead className="c-f-view-vtable-head">
                <tr className="c-f-view-vtable-row">
                    <th className="c-f-view-vtable-header">Name</th>
                    <th className="c-f-view-vtable-header">Points</th>
                    <th className="c-f-view-vtable-header">Crusade Points</th>
                </tr>
                </thead>
                <tbody className="c-f-view-vtable-body">
                {force?.cards?.map(card =>
                    <tr className="c-f-view-vtable-row">
                        <td className="c-f-view-vtable-cell c-f-view-vtable-cell-name">{card.name}</td>
                        <td className="c-f-view-vtable-cell c-f-view-vtable-cell-points">{card.points}</td>
                        <td className="c-f-view-vtable-cell c-f-view-vtable-cell-c-points">{card.crusadePoints}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    </>
}

export default Cards;