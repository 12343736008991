import React, { useState} from "react";
import {Avatar, Menu, Space} from "antd";
import { useNavigate } from "react-router-dom";
import {isAdminAuthenticated, isUserAuthenticated} from "../user/auth/Auth";
import {useCurrentUserContext} from "../user/CurrentUserContext";
import {UserOutlined} from "@ant-design/icons";
import logo from "../_components/images/logo1200_processed.png";
import "./styles-menu.css"

const AuthenticatedUserMainMenu = () => {
    const navigate = useNavigate();

    const [home, setHome] = useState(true);
    const [games, setGames] = useState(false);
    const [tournaments, setTournaments] = useState(false);
    const [crusade, setCrusade] = useState(false);

    const { currentUser } = useCurrentUserContext();

    function onHome(e) {
        e.preventDefault();
        setHome(true)
        setGames(false)
        setTournaments(false)
        setCrusade(false)
        navigate("/home")
    }

    function onGames(e) {
        e.preventDefault();
        setHome(false)
        setGames(true)
        setTournaments(false)
        setCrusade(false)
        navigate("/games")
    }

    function onTournaments(e) {
        e.preventDefault();
        setHome(false)
        setGames(false)
        setTournaments(true)
        setCrusade(false)
        navigate("/tournaments")
    }

    function onCrusade(e) {
        e.preventDefault();
        setHome(false)
        setGames(false)
        setTournaments(false)
        setCrusade(true)
        navigate("/crusade/forces")
    }

    return (
        <>
            { isUserAuthenticated(currentUser) &&
                < nav className="m-navbar">
                    <div ><img  className="m-logo" src={logo} alt=""/></div>
                    <ul className="m-menu">
                        <li><a href="#"
                                className={home ? 'm-menu-active' : ''}
                               onClick={(e) => onHome(e)}>Home</a></li>
                        <li><a href="#"
                               className={games ? 'm-menu-active' : ''}
                               onClick={(e) => onGames(e)}>Games</a></li>
                        <li><a href="#"
                               className={tournaments ? 'm-menu-active' : ''}
                               onClick={(e) => onTournaments(e)}>Tournaments</a></li>
                        <li>
                            <span className="m-span-voting-tag">
                                <span className="m-tag"><a href="#"
                                                           className={crusade ? 'm-menu-active' : ''}
                                                           onClick={(e) => onCrusade(e)}>Crusade</a></span>
                                <span className="m-tag_badge">ALPHA</span>
                            </span>
                        </li>
                    </ul>
                    <div className="m-user-info">
                        <span className="m-username">{currentUser?.username}</span>
                        <i className="fa-solid fa-user"></i>
                    </div>
                </nav>
            }
        </>
    );
};

export default AuthenticatedUserMainMenu;

