import React, {useEffect, useState} from "react";

import 'react-calendar/dist/Calendar.css';
import SearchDate from "./SearchDate";

const DateRange = ({updateStartDate, updateEndDate}) => {

    const [start, setStart] = useState(new Date().setDate(new Date().getDate() - 3))
    const [end, setEnd] = useState(new Date().setDate(new Date().getDate() - 0))

    useEffect(() => {
        updateStartDate(start)
    }, [start])

    useEffect(() => {
        updateEndDate(end)
    }, [end])

    return (
        <>
            <div className="date">
                <span>
                    <i className="fa-solid fa-calendar h-fa-calendar"></i>
                    <span>
                        <SearchDate date={start} updateDate={setStart}/>
                        <span> - </span>
                        <SearchDate date={end} updateDate={setEnd} />
                    </span></span>
            </div>
        </>
    );
};

export default DateRange;

