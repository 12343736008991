import React, {useEffect, useState} from "react";


import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import moment from "moment";


const SearchDate = ({date, updateDate}) => {

    const [isEdit, setIsEdit] = useState(false)

    return (
        <>
            {!isEdit && <span onClick={() => setIsEdit(true)}>{moment(date).format('DD MMM YYYY')}</span>}

            {isEdit &&  <span onBlur={() => setIsEdit(false)}>
                <Calendar value={date} onChange={(value) => {updateDate(value.getTime());setIsEdit(false); }} />
            </span> }
        </>
    );
};

export default SearchDate;

