import React, {useEffect, useState} from "react";

import News from "./news/News";
import {GoogleAnalyticsPageView} from "../GoogleAnalytics";
import {useCurrentUserContext} from "../user/CurrentUserContext";
import {useSearchParams} from "react-router-dom";

import Menu from "./news/Menu";

import "./styles-h.css"

const Home = () => {

    GoogleAnalyticsPageView("/home", "Home")

    const {setRef} = useCurrentUserContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const [startDate, setStartDate] = useState(new Date().setDate(new Date().getDate() - 3))
    const [endDate, setEndDate] = useState(new Date().setDate(new Date().getDate() - 0))

    useEffect(() => {
        setRef(searchParams.get("ref"))
    }, [])

    return (
        <>

            <div className="h-container-cards">
{/*
                <Space direction={"horizontal"}>
                    <ActivePlayers />
                    <GamesPlayed />
                    <WinPerForFaction />
                    <Top5Users />
                </Space>
                <Space direction={"horizontal"}>
                    <UpcomingGames />
                    <TournamentsCloseToUser />
                </Space>
*/}
                <Menu updateStartDate={setStartDate} updateEndDate={setEndDate} />
                <News startDate={startDate} endDate={endDate} />
            </div>
        </>
    );
};

export default Home;

