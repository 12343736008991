import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input,
    Select, Space,
    Table
} from "antd";

import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Errors from "./components/Errors";
import Universes from "./components/Universes";
import Participants from "./components/Participants";
import {doRestCall} from "../../AppUtils";
import "./styles-g-create.css"

const {TextArea} = Input;


const GameCreate = () => {

    GoogleAnalyticsPageView("/game/create", "Game Create")

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState({});
    const [errors, setErrors] = useState(undefined)
    const [selectedSettings, setSelectedSettings] = useState([])
    const [selectedUniverse, setSelectedUniverse] = useState()

    const [game, setGame] = useState({});

    useEffect(() => {
        doRestCall('/users/all', 'get', null, null, onSuccessUsersAll)
    }, [])

    useEffect(() => {
        game.universe = selectedUniverse;
    }, [selectedUniverse])

    function navigateBack(e) {
        e.preventDefault();
        navigate("/games")
    }

    function onSuccessUsersAll(response) {
        for (let i = 0; i < response.body.length; i++) {
            response.body[i] = {
                id: response.body[i].id,
                name: response.body[i].username,
                value: response.body[i].id,
                label: response.body[i].username,
            }
        }

        setUsers(response.body)
        setUsersLoading(false)
    }

    function onSuccessCreate(response) {
        if (response.valid) {
            setErrors([])
            navigate('/game/view/' + response.body.id)
        }

        if (!response.valid) {
            setErrors(response.errors)
        }
    }

    function onFinish(event) {
        event.preventDefault();

        doRestCall('/game/create', 'post', null, game, onSuccessCreate)
    }

    const filterGameMasters = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <Auth/>
            <div className="g-c-create-game-section mt-5">
                <div className="g-c-create-game-container">
                    <div className="g-c-create-game-header mb-5">
                        <h2>Create New Game</h2>
                    </div>

                    <form onSubmit={onFinish}>
                        <div className="g-c-create-game-form">
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-12">
                                    <div className="form mb-5">
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-3"><label>Name</label></div>
                                                <div className="col-md-9"><input type="text"
                                                                                 placeholder="Name"
                                                                                 onChange={(event) => game.name = event.target.value}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-3"><label className="form-label">Date</label>
                                                </div>
                                                <div className="col-md-9"><input type="date"
                                                                                 className="form-control"
                                                                                 placeholder="Date"
                                                                                 onChange={(event) => game.dateTime = event.target.value}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-3"><label className="form-label">Game
                                                    Master</label></div>
                                                <div className="col-md-9">
                                                    <Select
                                                        className="select"
                                                        options={users}
                                                        data-cy="gameMaster"
                                                        showSearch
                                                        filterOption={filterGameMasters}
                                                        onChange={(value) => game.gameMasterId = value}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-md-3"><label className="form-label">Universe</label>
                                                </div>
                                                <div className="col-md-9">
                                                    <Universes setSelectedUniverse={setSelectedUniverse}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <div className="row">
                                                <div className="col-md-3"><label
                                                    className="form-label">Notes</label></div>
                                                <div className="col-md-9">
                                                        <textarea name="" id="" className="form-control"
                                                                  rows="4"
                                                                  onChange={(event) => game.notes = event.target.value}></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <Participants game={game} selectedUniverse={selectedUniverse}/>

                                    </div>
                                    <div className="row d-flex justify-content-between">
                                        <div className="col-auto">
                                            <div className="g-c-tlist-btn" onClick={(e) => navigateBack(e)}>Back</div>
                                        </div>
                                        <div className="col-auto">
                                            <button className="g-c-tlist-btn-active"
                                                    type="submit"
                                                    data-cy="submit">
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default GameCreate;

