import React, {useEffect, useState} from 'react';
import {Button, InputNumber, Popover, Select, Space, Spin} from 'antd';
import {updateBattleScars} from "../../CardConnector";
import {
    notificationBattleScarsUpdateSuccess,
    notificationBattleTraitsUpdateSuccess,
    notificationFailure, notificationRelicsUpdateSuccess, notificationWeaponModificationsUpdateSuccess
} from "../../CardNotifications";
import {doRestCall} from "../../../../AppUtils";

const Relics = ({forceId, cardId, card}) => {

    const [selectedRelics, setSelectedRelics] = useState([])
    const [loadedRelics, setLoadedRelics] = useState([])
    const [loadedRelicsOptions, setLoadedRelicsOptions] = useState([])

    const [num, setNum] = useState(0)

    useEffect(() => {
        if (card !== undefined && card !== null && card.relics !== null && card.relics !== undefined) {
            setNum(card?.relics?.length)
            setSelectedRelics(card?.relics)
        }
    }, [card])

    useEffect(() => {
        if (card !== undefined &&  card !== null) {
            doRestCall('/codex/crusade/relics', 'get', {
                    keywords: card.keywords
                }, null,
                (response) => setLoadedRelics(response.body))
        }
    }, [card])


    useEffect(() => {
        if (loadedRelics !== undefined && loadedRelics !== null) {
            let tempArtificer = []
            let filteredArtificer = loadedRelics.filter(relic => relic.category === 'ARTIFICER');
            for (let i = 0; i < filteredArtificer.length; i++) {
                tempArtificer[i] = {
                    value: filteredArtificer[i].key,
                    label: filteredArtificer[i].label,
                }
            }
            let tempAntiquity = []
            let filteredAntiquity = loadedRelics.filter(relic => relic.category === 'ANTIQUITY');
            for (let i = 0; i < filteredAntiquity.length; i++) {
                tempAntiquity[i] = {
                    value: filteredAntiquity[i].key,
                    label: filteredAntiquity[i].label,
                }
            }
            let tempLegendary = []
            let filteredLegendary = loadedRelics.filter(relic => relic.category === 'LEGENDARY');
            for (let i = 0; i < filteredLegendary.length; i++) {
                tempLegendary[i] = {
                    value: filteredLegendary[i].key,
                    label: filteredLegendary[i].label,
                }
            }
            setLoadedRelicsOptions([
                {
                    label: 'ARTIFICER',
                    options: tempArtificer
                },
                {
                    label: 'ANTIQUITY',
                    options: tempAntiquity
                },
                {
                    label: 'LEGENDARY',
                    options: tempLegendary
                }
            ])
        }


    }, [loadedRelics]);

    const onRelicsUpdate = (index,value) => {
        if (index !== undefined && value !== undefined) {
            if (value === '') {
                selectedRelics.splice(index, 1)
            } else {
                selectedRelics[index] = value
            }
        } else {
            setNum(0)
            selectedRelics.splice(0, selectedRelics.length)
        }

        doRestCall('/crusade/card/update/relics', 'put', null,{
                forceId: forceId,
                cardId: cardId,
                relics: selectedRelics
            },
            () => notificationRelicsUpdateSuccess(),
            () => notificationFailure())
        setSelectedRelics(selectedRelics)
    }

    function removeRelic(index) {
        setNum(num - 1);
        onRelicsUpdate(index, '')
    }

    function addRelic(index) {
        setNum(num + 1)
    }

    function reset(e) {
        e.preventDefault();
        onRelicsUpdate()
    }

    return <>
        <div className="mb-5">
            <div className="row">
                <div className="col-md-4 text-end"><label className="form-label">Relics</label></div>
                <div className="col-md-6">
                    {num === 0 && <p>none <i className="fa-solid fa-plus" onClick={() => setNum(num + 1)}></i></p>}

                    {Array.from(Array(num)).map((_, index) => (<div className="col-md-6" style={{width: "100%"}}>
                            <Select
                                placeholder="Select relic"
                                defaultValue={selectedRelics && selectedRelics[index]}
                                options={loadedRelicsOptions}
                                onChange={(value) => onRelicsUpdate(index, value)}/>
                            {index+1 === num && <i className="fa-solid fa-plus" onClick={() => addRelic(index)}></i> }
                            {index+1 === num && <i className="fa-solid fa-minus" onClick={() => removeRelic(index)}></i>}
                            {index + 1 === num && <a href="#" onClick={(e) => reset(e)}>Reset</a>}
                        </div>

                    ))}
                </div>
            </div>
        </div>

    </>
}

export default Relics;