import React, {useEffect, useState} from "react";
import {
     useStripe, useElements, CardElement
} from "@stripe/react-stripe-js";
import {useLocation, useNavigate} from "react-router-dom";

const Checkout = () => {

    const {
        state: {
            clientSecret,
        }
    } = useLocation();

    const stripe = useStripe();
    const elements = useElements();

    const [name, setName] = useState('Jenny Rosen');
    const [messages, setMessages] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const cardElement = elements.getElement(CardElement);

        const { error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: name,
                }
            }
        });

        if(error) {
            // show error and collect new card details.
            setMessages(error.message);
            return;
        }

        navigate('/complete', {
            state: {
                clientSecret
            }
        });

    };

    return (<>
        <h1>Subscribe</h1>

        <p>
            Try the successful test card: <span>4242424242424242</span>.
        </p>

        <p>
            Try the test card that requires SCA: <span>4000002500003155</span>.
        </p>

        <p>
            Use any <i>future</i> expiry date, CVC,5 digit postal code
        </p>

        <hr/>
        <form onSubmit={handleSubmit}>
            <label>
                Full name
                <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)}/>
            </label>

            <CardElement/>

            <button>
                Subscribe
            </button>

            <div>{messages}</div>
        </form>

    </>);
}

export default Checkout;
