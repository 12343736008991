import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";
import CardPrint from "./CardPrint";

const CardsPrint = ({cards}) => {


    return <>
        <div className="page"/>
        <br />
        <br />

        <div className="container">
            {cards?.map((card, index) => <div>
                    <CardPrint card={card} />
                <hr/>
                { cards?.length !== index + 1 && <div className="page"/> }
                </div>
            )}
        </div>
    </>
};

export default CardsPrint;
