import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const DeleteButton = ({forceId, ownerId}) => {

    const {currentUser} = useCurrentUserContext();
    const navigate = useNavigate();

    function onSuccess() {
        navigate('/crusade/forces')
    }

    function onConfirmDelete() {
        doRestCall('/crusade/force/' + forceId + '/delete', 'delete', null, null, onSuccess)
    }

    return <>
        {(currentUser?.role === 'ADMIN' || currentUser?.id === ownerId)
            && <Popconfirm title={"Delete force"}
                           description={"Are you sure you want to delete this force?"}
                           onConfirm={onConfirmDelete}>
                <button className="c-f-view-tlist-btn-active">Delete</button>
            </Popconfirm>}
    </>
}


export default DeleteButton;