import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, InputNumber, Select, Space, Table} from "antd";
import {usersAll} from "../../../user/UserConnector";
import moment from "moment/moment";
import Factions from "./Factions";
import {doRestCall} from "../../../AppUtils";


const Participants = ({participants, selectedUniverse, updatedParticipants}) => {

    const [players, setPlayers] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [selectedPlayerId, setSelectedPlayerId] = useState([]);

    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

    useEffect(() => {
        selectedPlayers?.length >= 2 ? setIsAddButtonDisabled(true) : setIsAddButtonDisabled(false)
    }, [selectedPlayers])

    function convertToPlayers(response) {
        let temp = []

        for (let i = 0; i < response.body.length; i++) {
            temp[i] = {
                id: response.body[i].id,
                key: response.body[i].key,
                name: response.body[i].username,
                value: response.body[i].id,
                label: response.body[i].username,
                faction: response.body[i].faction,
            }
        }

        setPlayers(temp)
    }

    useEffect(() => {
        doRestCall('/users/all', 'get', null, null,
            (response) => convertToPlayers(response))
    }, []);


    useEffect(() => {
        let obj = []
        selectedPlayers && selectedPlayers.map(player => {
            obj.push({
                "userId": player.id,
                "winner": player.winner,
                "score": player.score,
                "faction": player.faction,
                "username": player.username
            })
        });

        updatedParticipants(obj)


    }, [selectedPlayers])

    useEffect(() => {
        participants && participants.forEach(participant => {
            participant.name = participant.username
            participant.id = participant.userId
        })

        setSelectedPlayers(participants)
    }, [participants])


    const handleDelete = (key) => {
        const newData = selectedPlayers.filter((item) => item.key !== key);
        setSelectedPlayers(newData);
    };

    const handleAdd = () => {
        const newData = players.filter(player => player.id === selectedPlayerId)[0]
        setSelectedPlayers([...selectedPlayers, newData]);
    };

    const onPlayerSelected = (selectedPlayerId) => {
        setSelectedPlayerId(selectedPlayerId);
        setIsAddButtonDisabled(selectedPlayerId === undefined || selectedPlayers?.length >= 2);
    };

    const onWinnerChange = (row, isChecked) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.winner = isChecked;

        setSelectedPlayers([...selectedPlayers])
    }

    const onFactionChange = (row, faction) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.faction = faction;

        setSelectedPlayers([...selectedPlayers])
    }

    const onScoreChanged = (row, score) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.score = score;

        setSelectedPlayers([...selectedPlayers])
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
    }, {
        title: 'Faction', dataIndex: 'faction', key: 'faction',
        render: (faction, row) => (
            <Factions selectedUniverse={selectedUniverse} preSelectedFaction={faction}
                      setSelectedFaction={(faction) => onFactionChange(row, faction)}/>
        )
    }, {
        title: 'Score', dataIndex: 'score', key: 'score',
        render: (score, row) => (
            <InputNumber value={score} min={0} onChange={(value) => onScoreChanged(row, value)}/>
        )
    }, {
        title: 'Winner', dataIndex: 'winner', key: 'winner',
        render: (winner, row) => (
            <Checkbox defaultChecked={winner} onChange={(e) => onWinnerChange(row, winner, e.target.checked)}/>
        )
    }, {
        title: 'Actions', dataIndex: 'actions', key: 'actions',
        render: (_, record) => (
            <Space direction="horizontal"><Button onClick={() => handleDelete(record.key)}>Delete</Button></Space>
        )
    }];

    return <>
        <h3 className="mb-3">Participants</h3>
        <div className="mb-3">
            <div className="row">
                <div className="col-md-2"><label
                    className="form-label">Search</label></div>
                <div className="col-md-7"><Select
                    optionFilterProp="label"
                    showSearch={true}
                    options={players}
                    onChange={onPlayerSelected}
                    allowClear={true}
                    className="select"
                />
                </div>
                <div className="col-md-3">
                    <div
                        className={isAddButtonDisabled || selectedPlayerId.length === 0 ? "g-e-btn-sm-disabled" : "g-e-btn-sm"}
                        onClick={handleAdd}>Add
                    </div>
                </div>
            </div>
        </div>
        <div className="mb-3">
            <table className="g-e-vtable">
                <thead className="g-e-vtable-head">
                <tr className="g-e-vtable-row">
                    <th className="g-e-vtable-header">Name</th>
                    <th className="g-e-vtable-header">Faction</th>
                    <th className="g-e-vtable-header">Score</th>
                    <th className="g-e-vtable-header">Winner</th>
                    <th className="g-e-vtable-header">Action</th>
                </tr>
                </thead>
                <tbody className="g-e-vtable-body">

                {selectedPlayers?.map((selectedPlayer) =>
                    <tr className="g-e-vtable-row">
                        <td className="g-e-vtable-cell g-e-vtable-cell-p-name">{selectedPlayer.name}</td>
                        <td className="g-e-vtable-cell">
                            <Factions preSelectedFaction={selectedPlayer.faction} selectedUniverse={selectedUniverse}
                                      setSelectedFaction={(faction) => onFactionChange(selectedPlayer, faction)}/>
                        </td>
                        <td className="g-e-vtable-cell"><InputNumber
                            value={selectedPlayer.score}
                            min={0}
                            onChange={(value) => onScoreChanged(selectedPlayer, value)}/>
                        </td>
                        <td className="g-e-vtable-cell">
                            {selectedPlayer?.winner ?
                                <i className="fa-solid fa-check"
                                   onClick={() => onWinnerChange(selectedPlayer, !selectedPlayer.winner)}></i> :
                                <i className="fa-regular fa-square"
                                   onClick={() => onWinnerChange(selectedPlayer, !selectedPlayer.winner)}></i>}
                        </td>
                        <td className="g-e-vtable-cell">
                            <i className="fa-solid fa-trash" onClick={() => handleDelete(selectedPlayer.key)}></i>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

    </>
}

export default Participants;