import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {doRestCall} from "../../../AppUtils";
import Spinner from "../../../_components/Spinner";
import Factions from "./Factions";

const DetailsRegistrationOpen = ({universe, tournamentId, setAlreadyRegistered, onTournamentUpdate }) => {

    const [loading, setLoading] = useState(false)
    const [selectedFaction, setSelectedFaction] = useState()

    function register() {
        setLoading(true)
        doRestCall('/tournament/register/' + tournamentId, 'post', null, {
                faction: selectedFaction
            },
            (response) => {
                onTournamentUpdate(response.body)
                setAlreadyRegistered(true)
            },
            null,
            () => {
                setLoading(false)
            })
    }

    function factionSelected(faction) {
        setSelectedFaction(faction)
   }

    return <>
        <Spinner isLoading={loading} >
            <div className="t-v-line2"></div>
            <div className="t-v-join-info-ch">
                <div className="mb-3">
                    <Factions universe={universe} setSelectedFaction={factionSelected} />
                </div>
                <div className="mb-3">
                    <div className="col-auto">
                        <div className="t-v-tlist-btn t-v-text-center" onClick={() => {register()}}>Register</div>
                    </div>
                </div>
            </div>
        </Spinner>
    </>
}

export default DetailsRegistrationOpen;