import React, {useState} from "react";
import StripePrices from "./StripePrices";
import {Modal, Spin} from "antd";
import {
    useStripe, useElements, CardElement
} from "@stripe/react-stripe-js";
import {doRestCall} from "../AppUtils";

const Support = () => {

    const [name, setName] = useState();
    const [messages, setMessages] = useState('');
    const [priceId, setPriceId] = useState()
    const [clientSecret, setClientSecret] = useState()

    const [loading, setLoading] = useState(false)

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)

        const cardElement = elements.getElement(CardElement);

        const { error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: name,
                }
            }
        });

        if(error) {
            // show error and collect new card details.
            setMessages(error.message);
            setLoading(false)
            return;
        }

        setLoading(false)
        setIsModalOpen(false)
    };

    //

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    function subscriptionSelected(priceId) {
        // clean
        setName(undefined)
        setMessages(undefined)
        //

        setIsModalOpen(true)
        setLoading(true)
        setPriceId(priceId)

        doRestCall('/stripe/subscribe', 'post', null,
            {
                priceId: priceId
            },
            (response) => {
                setClientSecret(response.body.clientSecret)
            },
            null,
            () => {
                setLoading(false)
            })
    }

    return (
        <>
            support

            <StripePrices subscriptionSelected={subscriptionSelected} />

            <Modal title="Subscription"
                   open={isModalOpen}
                   onOk={handleSubmit}
                   onCancel={handleCancel}
                   destroyOnClose={true}>

                <Spin spinning={loading}>
                    <label>
                        Full name
                        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)}/>
                    </label>

                    <CardElement/>

                    <div>{messages}</div>
                </Spin>
            </Modal>
        </>
    );
};

export default Support;

