import React, {useEffect, useState} from "react";
import {
    Button,
    InputNumber,
    Table
} from "antd";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {myForces} from "../ForceConnector";
import {Auth} from "../../../user/auth/Auth";
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";

const MyForces = () => {

    GoogleAnalyticsPageView("/crusade/forces/my", "Crusade Forces My")

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [forces, setForces] = useState({});

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        myForces()
            .then(response => {
                setForces(response.body)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function view(id) {
        navigate('/crusade/force/' + id + '/view')
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
        render: (name, row) => (
            <Button data-cy="name" type="link" onClick={() => view(row.id)}>{name}</Button>
        )
    }];


    return (
        <>
            <Auth />
            {loading && <p>loading</p>}
            {!loading &&
            <>
              <p className="forceListTitle">My Crusade Forces</p>
                <Table
                    columns={columns}
                    dataSource={forces}
                    pagination={false}
                    id={"table"}
                    style={{width: '100%'}}
                    showHeader={true}
                    className="forceList"
                />
            </>
            }
        </>
    );
};

export default MyForces;

