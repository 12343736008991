import React, {useEffect, useState} from 'react';
import {Button, InputNumber, Popover, Select, Space, Spin} from 'antd';
import {updateBattleScars} from "../../CardConnector";
import {
    notificationBattleScarsUpdateSuccess,
    notificationBattleTraitsUpdateSuccess,
    notificationFailure, notificationWeaponModificationsUpdateSuccess
} from "../../CardNotifications";
import {doRestCall} from "../../../../AppUtils";

const WeaponModifications = ({forceId, cardId, card}) => {

    const [selectedWeaponModifications, setSelectedWeaponModifications] = useState([])
    const [loadedWeaponModifications, setLoadedWeaponModifications] = useState([])
    const [loadedWeaponModificationsOptions, setLoadedWeaponModificationsOptions] = useState([])

    const [num, setNum] = useState(0)

    useEffect(() => {
        if (card !== undefined && card !== null && card.weaponModifications !== null && card.weaponModifications !== undefined) {
            setNum(card?.weaponModifications?.length)
            setSelectedWeaponModifications(card?.weaponModifications)
        }
    }, [card])

    useEffect(() => {
        if (card !== undefined &&  card !== null) {
            doRestCall('/codex/crusade/weaponmodifications', 'get', {
                    keywords: card.keywords
                }, null,
                (response) => setLoadedWeaponModifications(response.body))
        }
    }, [card])


    useEffect(() => {
        if (loadedWeaponModifications !== undefined && loadedWeaponModifications !== null) {
            let temp = []
            for (let i = 0; i < loadedWeaponModifications.length; i++) {
                temp[i] = {
                    value: loadedWeaponModifications[i].key,
                    label: loadedWeaponModifications[i].label,
                }
            }
            setLoadedWeaponModificationsOptions(temp)
        }

    }, [loadedWeaponModifications]);


    const onWeaponModificationsUpdate = (index,value) => {
        if (index !== undefined && value !== undefined) {
            if (value === '') {
                selectedWeaponModifications.splice(index, 1)
            } else {
                selectedWeaponModifications[index] = value
            }
        } else {
            setNum(0)
            selectedWeaponModifications.splice(0, selectedWeaponModifications.length)
        }

        doRestCall('/crusade/card/update/weaponmodifications', 'put', null,{
                forceId: forceId,
                cardId: cardId,
                weaponModifications: selectedWeaponModifications
            },
            () => notificationWeaponModificationsUpdateSuccess(),
            () => notificationFailure())
        setSelectedWeaponModifications(selectedWeaponModifications)
    }

    function removeWeaponModification(index) {
        setNum(num - 1);
        onWeaponModificationsUpdate(index, '')
    }

    function addWeaponModification(index) {
        setNum(num + 1)
    }

    function reset(e) {
        e.preventDefault();
        onWeaponModificationsUpdate()
    }

    return <>
        <div className="mb-5">
            <div className="row">
                <div className="col-md-4 text-end"><label className="form-label">Weapon Modifications</label></div>
                <div className="col-md-6">
                    {num === 0 && <p>none <i className="fa-solid fa-plus" onClick={() => setNum(num + 1)}></i></p>}
                    {Array.from(Array(num)).map((_, index) => (<div className="col-md-6" style={{width: "100%"}}>
                            <Select
                                placeholder="Select battle trait"
                                defaultValue={selectedWeaponModifications && selectedWeaponModifications[index]}
                                className=""
                                options={loadedWeaponModificationsOptions}
                                onChange={(value) => onWeaponModificationsUpdate(index, value)}/>
                            {index+1 === num && <i className="fa-solid fa-plus" onClick={() => addWeaponModification(index)}></i> }
                            {index+1 === num && <i className="fa-solid fa-minus" onClick={() => removeWeaponModification(index)}></i>}
                            {index + 1 === num && <a href="#" onClick={(e) => reset(e)}>Reset</a>}
                        </div>

                    ))}
                </div>
            </div>
        </div>

    </>
}

export default WeaponModifications;