import React, {useEffect, useState} from "react";

import Rounds from "./components/Rounds";
import Participants from "./components/Participants";

import "./styles-t-view.css"
import Status from "./components/Status";
import Details from "./components/Details";

const TournamentStarted = ({tournament}) => {


    return (
        <>
            <div className="t-v-container-ch">
                <h1 className="t-v-heading-m-ch">{tournament.name}</h1>

                <Status status={tournament.status}/>

                <Details
                    status={tournament.status}
                    regStartDate={tournament.registrationStartDate}
                    regEndDate={tournament.registrationEndDate}
                    startDate={tournament.startDate}
                    endDate={tournament.endDate}
                    master={tournament.tournamentMaster}
                    universe={tournament.universe}
                    tournamentId={tournament.id}
                    onTournamentUpdate={() => {}}
                    participantsLimit={tournament.participantsLimit}
                    participantsCount={tournament.players?.length}
                />
            </div>

            <Participants tournamentId={tournament.id} tournamentPlayers={tournament.players}/>
            <Rounds tournamentId={tournament.id} rounds={tournament.rounds}/>
        </>
    )

};

export default TournamentStarted;

