import React, {useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {doRestCall} from "../../../AppUtils";
import Spinner from "../../../_components/Spinner";
import Factions from "./Factions";

const DetailsRegistrationAlreadyRegistered = ({ tournamentId, setAlreadyRegistered, onTournamentUpdate }) => {

    const [loading, setLoading] = useState(false)

    function unregister() {
        setLoading(true)
        doRestCall('/tournament/unregister/' + tournamentId, 'post', null, null,
            (response) => {
                onTournamentUpdate(response.body)
                setAlreadyRegistered(false)
            },
            null,
            () => {
                setLoading(false)
            })
    }

    return <>
        <Spinner isLoading={loading} >
            <div className="t-v-line2"></div>
            <div className="t-v-join-info-ch">
                <div className="mb-3">
                    <p>You are already registered</p>
                </div>
                <div className="mb-3">
                    <div className="col-auto">
                        <div className="t-v-tlist-btn t-v-text-center" onClick={() => {unregister()}}>Unregister</div>
                    </div>
                </div>
            </div>
        </Spinner>
    </>
}

export default DetailsRegistrationAlreadyRegistered;