import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, InputNumber, Select, Space, Table} from "antd";
import {usersAll} from "../../../user/UserConnector";
import moment from "moment/moment";
import Factions from "./Factions";
import {DeleteOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {doRestCall} from "../../../AppUtils";


const Participants = ({game, selectedUniverse}) => {
    const navigate = useNavigate();

    const [players, setPlayers] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [selectedPlayerId, setSelectedPlayerId] = useState([]);

    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

    useEffect(() => {
        selectedPlayers?.length >= 2 ? setIsAddButtonDisabled(true) : setIsAddButtonDisabled(false)
    }, [selectedPlayers])

    useEffect(() => {
        game.players?.forEach((player) => player.faction = "" )
    }, [selectedUniverse])

    function onSuccess(response) {
        for (let i = 0; i < response.body.length; i++) {
            response.body[i] = {
                id: response.body[i].id,
                key: response.body[i].key,
                name: response.body[i].username,
                value: response.body[i].id,
                label: response.body[i].username,
            }
        }

        setPlayers(response.body)
    }

    useEffect(() => {
        doRestCall( '/users/all', 'get', {}, {}, onSuccess)
    }, []);

    useEffect(() => {
        let obj = []
        selectedPlayers && selectedPlayers.map(player => {
            obj.push({
                "userId": player.id,
                "winner": player.winner,
                "score": player.score,
                "faction": player.faction
            })
        });

        game.players = obj;

    }, [selectedPlayers])

    const handleDelete = (key) => {
        const newData = selectedPlayers.filter((item) => item.key !== key);
        setSelectedPlayers(newData);
    };

    const handleAdd = () => {
        if (isAddButtonDisabled || selectedPlayerId.length === 0) {
            return
        }
        const newData = players.filter(player => player.id === selectedPlayerId)[0]
        setSelectedPlayers([...selectedPlayers, newData]);
    };

    const onPlayerSelected = (selectedPlayerId) => {
        setSelectedPlayerId(selectedPlayerId);
        setIsAddButtonDisabled(selectedPlayerId === undefined  || selectedPlayers?.length >= 2);
    };

    const onWinnerChange = (row, isChecked) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.winner = isChecked;

        setSelectedPlayers([...selectedPlayers])
    }

    const onFactionChange = (row, faction) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.faction = faction;

        setSelectedPlayers([...selectedPlayers])
    }

    const onScoreChanged = (row, score) => {
        let p = selectedPlayers.find(player => player.id === row.id);
        p.score = score;

        setSelectedPlayers([...selectedPlayers])
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction',
        render: (faction, row) => (
            <Factions selectedUniverse={selectedUniverse} setSelectedFaction={(faction) => onFactionChange(row, faction)} />
        )
    },{
        title: 'Score', dataIndex: 'score', key: 'score',
        render: (score, row) => (
            <InputNumber min={0} onChange={(value) => onScoreChanged(row, value)} />
        )
    },{
        title: 'Winner', dataIndex: 'winner', key: 'winner',
        render: (winner, row) => (
            <Checkbox onChange={(e) => onWinnerChange(row, winner, e.target.checked)} />
        )
    },{
        title: 'Actions', dataIndex: 'actions', key: 'actions',
        render: (_, record) => (
            <Space direction="horizontal"><Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.key)}></Button></Space>
        )
    }];

    return <>
        <h3 className="mb-3">Participants</h3>
        <div className="mb-3">
            <div className="row">
                <div className="col-md-2"><label
                    className="form-label">Search</label></div>
                <div className="col-md-7"> <Select
                    optionFilterProp="label"
                    showSearch={true}
                    options={players}
                    onChange={onPlayerSelected}
                    allowClear={true}
                    className="select"
                />
                </div>
                <div className="col-md-3">
                    <div className={isAddButtonDisabled || selectedPlayerId.length === 0 ? "g-c-btn-sm-disabled" : "g-c-btn-sm"}  onClick={handleAdd}>Add</div>
                </div>
            </div>
        </div>
        <div className="mb-3">
            <table className="g-c-vtable">
                <thead className="g-c-vtable-head">
                <tr className="g-c-vtable-row">
                    <th className="g-c-vtable-header">Name</th>
                    <th className="g-c-vtable-header">Faction</th>
                    <th className="g-c-vtable-header">Score</th>
                    <th className="g-c-vtable-header">Winner</th>
                    <th className="g-c-vtable-header">Action</th>
                </tr>
                </thead>
                <tbody className="g-c-vtable-body">

                {selectedPlayers?.map((selectedPlayer) =>
                    <tr className="g-c-vtable-row">
                        <td className="g-c-vtable-cell g-c-vtable-cell-p-name">{selectedPlayer.name}</td>
                        <td className="g-c-vtable-cell">
                            <Factions selectedUniverse={selectedUniverse} setSelectedFaction={(faction) => onFactionChange(selectedPlayer, faction)} />
                        </td>
                        <td className="g-c-vtable-cell"><InputNumber min={0} onChange={(value) => onScoreChanged(selectedPlayer, value)} /></td>
                        <td className="g-c-vtable-cell">
                            {selectedPlayer?.winner ?
                                <i className="fa-solid fa-check" onClick={() => onWinnerChange(selectedPlayer, !selectedPlayer.winner)}></i> :
                                <i className="fa-regular fa-square" onClick={() => onWinnerChange(selectedPlayer, !selectedPlayer.winner)}></i>}
                        </td>
                        <td className="g-c-vtable-cell">
                            <i className="fa-solid fa-trash" onClick={() => handleDelete(selectedPlayer.key)}></i>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    </>
}

export default Participants;