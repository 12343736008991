import React, {useEffect, useState} from "react";
import {Form, Table} from "antd";
import Factions from "./Factions";
import {doRestCall} from "../../../AppUtils";
import ParticipantCheck from "../../view/components/ParticipantCheck";



const Participants = ({tournamentId, tournamentPlayers, selectedUniverse}) => {

    const [userTableSelectedRowKeys, setUserTableSelectedRowKeys] = useState();
    const [users, setUsers] = useState([]);
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        doRestCall( '/tournament/users', 'get', null, null, onAllUsersSuccess)
    }, [])


    useEffect(() => {
        for (let i = 0; i < tournamentPlayers?.length; i++) {
            tournamentPlayers[i] = {
                id: tournamentPlayers[i].id,
                name: tournamentPlayers[i].username,
                value: tournamentPlayers[i].id,
                label: tournamentPlayers[i].username,
                key: tournamentPlayers[i].key,
                score: tournamentPlayers[i].score,
                faction: tournamentPlayers[i].faction
            }
        }

        setPlayers(tournamentPlayers)

    }, [tournamentPlayers])

/*
    useEffect(() => {
        players && setUserTableSelectedRowKeys(users.filter(value => players.map(tp => tp.id).includes(value.id)).map(value => value.key));
    }, [users, players])

*/

    function onAllUsersSuccess(response) {
        for (let i = 0; i < response.body.length; i++) {
            response.body[i] = {
                id: response.body[i].id,
                name: response.body[i].username,
                value: response.body[i].id,
                label: response.body[i].username,
                key: response.body[i].key,
                faction: response.body[i].faction
            }
        }
        setUsers(response.body)
    }


    useEffect(() => {
        users.forEach(user => user.selected = players?.map(player => player.id).includes(user.id))
    }, [users, players])

    const usersTableColumns = [{
        title: 'Name', dataIndex: 'label', key: 'key', width: '60%',
    }, {
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '40%',
        render: (_, row) => (<>
                <Factions selectedUniverse={selectedUniverse} preSelectedFaction={row.faction} setSelectedFaction={(value) => {
                    doRestCall('/tournament/update/participant/faction', 'put', null, {
                        tournamentId: tournamentId,
                        participantId: row.id,
                        faction: value
                    })
                }}
                />
            </>
        )
    }
    ];

    const usersRowSelection = {
        selectedRowKeys: userTableSelectedRowKeys, onChange: (selectedRowKeys, selectedRows) => {
            doRestCall('/tournament/update/participants', 'put', null, {
                tournamentId: tournamentId,
                participantIds: selectedRows.map(row => row.id)
            })
            setUserTableSelectedRowKeys(selectedRowKeys);
        },
    };

    function userSelected(user) {
        console.log({
            players: players
        })
        return players?.map(player => player.id).includes(user.id);
    }

    function updateSelected(user) {
        console.log("updateSelected")
        user.selected = !user.selected
    }

    function addUser(user) {
    }

    return (
        <>
            <div className="t-e-vtable1-main">
                <div className="t-e-vtable-container">
                    <h2 className="t-e-vtable-title2">Participants</h2>
                    <table className="t-e-vtable">
                        <thead className="t-e-vtable-head">
                        <tr className="t-e-vtable-row">
                            <th className="t-e-vtable-header"></th>
                            <th className="t-e-vtable-header">Player</th>
                            <th className="t-e-vtable-header">Faction</th>
                        </tr>
                        </thead>
                        <tbody className="t-e-vtable-body">
                        {users?.map(user =>
                            <tr className="t-e-vtable-row">
                                <td className="t-e-vtable-cell">
                                    <ParticipantCheck user={user} tournamentId={tournamentId} />
                                </td>
                                <td className="t-e-vtable-cell t-e-table-cell-p-name">
                                    {user.name}
                                </td>
                                <td className="t-e-vtable-cell">faction</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>


{/*
            <i className="fa-solid fa-check"
               onClick={() => onWinnerChange(selectedPlayer, !selectedPlayer.winner)}></i> :
            <i className="fa-regular fa-square"
               onClick={() => onWinnerChange(selectedPlayer, !selectedPlayer.winner)}></i>
*/}

 {/*           <Table
                columns={usersTableColumns}
                dataSource={users}
                pagination={
                    {
                        pageSize: 10
                    }
                }
                id={"usersTable"}
                style={{width: '100%'}}
                bordered
                showHeader={true}
                rowSelection={{
                    type: 'checkbox', ...usersRowSelection,
                }}
                className="tournEditTable"
            />
*/}

            {/*
            {(users.length === 0) &&
                <>
                    <h2 className="tournEditTableTitles">Participants:</h2>
                    <div className="tournEditNoTable"> No Participants Found</div>
                </>
            }
            {(users.length > 0) &&
                <>
                    <h2 className="tournEditTableTitles">Participants:</h2>
                    <Form.Item name="usersFormItem">
                        <Table
                            columns={usersTableColumns}
                            dataSource={users}
                            pagination={
                                {
                                    pageSize: 10
                                }
                            }
                            id={"usersTable"}
                            style={{width: '100%'}}
                            bordered
                            showHeader={true}
                            rowSelection={{
                                type: 'checkbox', ...usersRowSelection,
                            }}
                            className="tournEditTable"
                        />
                    </Form.Item>
                </>
            }
*/}

        </>
    );
};

export default Participants;

