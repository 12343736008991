import React, {useEffect, useState} from "react";

import {doRestCall} from "../../AppUtils";


const Pagination = ({pagination, selectedPage}) => {

    const [totalPages, setTotalPages] = useState(pagination?.totalPages)
    const [current, setCurrent] = useState(pagination?.currentPage)

    useEffect(() => {
        setTotalPages(pagination?.totalPages)
        setCurrent(pagination?.currentPage)
    }, [pagination])


    return (
        <>
            <div className="h-pagination"> &#60; {Array.from({length: totalPages}, (_, i) => i + 1).map((index) =>
                <a href='#' onClick={(e) => {
                    e.preventDefault()
                    selectedPage(index)
                }} className={index === current ? 'h-pagination-active' : ''}>{index}</a>
            )} &#62;</div>
        </>
    )

};

export default Pagination;

