import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input, InputNumber,
    Select,
    Space,
    Table, Typography
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import moment from "moment";

import {GoogleAnalyticsPageView} from "../../GoogleAnalytics";
import Factions from "./components/Factions";
import Universes from "./components/Universes";
import {doRestCall} from "../../AppUtils";
import Rounds from "./components/Rounds";
import Errors from "./components/Errors";
import Participants from "./components/Participants";
import Master from "./components/Master";
import TournamentTag from "../view/components/TournamentTag";
import TournamentTags from "./components/TournamentTags";
import Settings from "./components/Settings";

import "./styles-t-edit.css"

const { TextArea } = Input;
const { Text } = Typography;

const TournamentEdit = () => {

    GoogleAnalyticsPageView("/tournament/edit", "Tournament Edit")

    const navigate = useNavigate();
    let {tournamentId} = useParams();

    const [form] = Form.useForm();

    const [usersLoading, setUsersLoading] = useState(true)

    const [gamesLoading, setGamesLoading] = useState(true)
    const [games, setGames] = useState([]);

    const [loading, setLoading] = useState(true)
    const [tournament, setTournament] = useState({});

    const [players, setPlayers] = useState([]);

    const [gameTableSelectedRowKeys, setGameTableSelectedRowKeys] = useState();

    const [selectedUniverse, setSelectedUniverse] = useState()

    const [selectedRowKeys, setRowKeys] = useState();

    const [participantLimit, setParticipantLimit] = useState(0)

    const [errors, setErrors] = useState(undefined)


    form.setFieldsValue({id: tournamentId})

    useEffect(() => {
        loadGamesEffect()
        loadTournament();
    }, [])

    useEffect(() => {
        form.setFieldsValue({universe: selectedUniverse});
    }, [selectedUniverse])

    useEffect(() => {
        form.setFieldsValue({
            name: tournament.name,
            createdDateTime: tournament.createdDateTime,
            status: tournament.status,
            tournamentMasterId: tournament.tournamentMasterId,
            gameIds: tournament.games && tournament.games.map(tg => tg.id),
            notes: tournament.notes
        })


        tournament.registrationStartDate && form.setFieldsValue({
            registrationStartDate: moment(tournament.registrationStartDate)
        })

        tournament.registrationEndDate && form.setFieldsValue({
            registrationEndDate: moment(tournament.registrationEndDate)
        })

        tournament.startDate && form.setFieldsValue({
            startDate: moment(tournament.startDate)
        })

        tournament.endDate && form.setFieldsValue({
            endDate: moment(tournament.endDate)
        })

        setParticipantLimit(tournament.participantsLimit)
    }, [tournament, games])

    function onViewSuccess(response) {
        setTournament(response.body)
        setLoading(false)
    }

    const loadTournament = () => {
        doRestCall( '/tournament/view/' + tournamentId, 'get', null, null, onViewSuccess)
    }

    function onAllGamesSuccess(response) {

        for (let i = 0; i < response.body.length; i++) {
            response.body[i] = {
                id: response.body[i].id,
                name: response.body[i].name,
                value: response.body[i].id,
                label: response.body[i].name,
                key: response.body[i].key
            }
        }

        setGames(response.body)
        setGamesLoading(false)

    }

    const loadGamesEffect = () => {
        doRestCall( '/tournament/games', 'get', null, null, onAllGamesSuccess)
    }

    function onTournamentUpdateSuccess(response) {
        if (response.valid) {
            navigate('/tournament/view/' + response.body.id)
        }
    }

    const save = (tournament) => {
        doRestCall( '/tournament/update', 'put', null, tournament, onTournamentUpdateSuccess)
    }

    function onFinish(values) {
        save(values)
    }

    function navigateBack() {
        navigate("/tournament/view/" + tournamentId)
    }
    return (
        <>
            <Auth/>

            <div className="t-e-create-game-section mt-5">
                <div className="t-e-create-game-container">
                    <div className="t-e-create-game-header mb-5">
                        <h2 className="t-e-title2">Edit Tournament</h2>
                    </div>
                    <div className="t-e-create-game-form">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-12">
                                <div className="form mb-5">
                                    <div className="t-e-mb-3">
                                        <div className="row">
                                            <div className="col-md-3"><label className="form-label">Name</label></div>
                                            <div className="col-md-9">
                                                <input type="text" placeholder="Name" defaultValue={tournament.name}
                                                       onChange={(event) => {
                                                           doRestCall('/tournament/update/name', 'put', null, {
                                                               tournamentId: tournament.id,
                                                               name: event.target.value
                                                           })
                                                       }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row t-e-mb-3">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6"><label className="form-label">Start Date</label></div>
                                                <div className="col-md-6">
                                                    <input type="date"
                                                           defaultValue={tournament.startDate && moment(tournament.startDate).format('yyyy-MM-DD')}
                                                           className="form-control"
                                                           placeholder="Date"
                                                           onBlur={(event) => {
                                                               doRestCall('/tournament/update/startdate', 'put', null, {
                                                                   tournamentId: tournament.id,
                                                                   startDate: event.target.value
                                                               })
                                                           }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6"><label className="form-label">Registration Start</label></div>
                                                <div className="col-md-6">
                                                    <input type="date"
                                                           defaultValue={tournament.registrationStartDate && moment(tournament.registrationStartDate).format('yyyy-MM-DD')}
                                                           className="form-control"
                                                           placeholder="Date"
                                                           onBlur={(event) => {
                                                               doRestCall('/tournament/update/regstartdate', 'put', null, {
                                                                   tournamentId: tournament.id,
                                                                   regStartDate: event.target.value
                                                               })
                                                           }}/>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="row t-e-mb-3">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6"><label className="form-label">End Date</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="date"
                                                           defaultValue={tournament.endDate && moment(tournament.endDate).format('yyyy-MM-DD')}
                                                           className="form-control"
                                                           placeholder="Date"
                                                           onBlur={(event) => {
                                                               doRestCall('/tournament/update/enddate', 'put', null, {
                                                                   tournamentId: tournament.id,
                                                                   endDate: event.target.value
                                                               })
                                                           }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6"><label className="form-label">Registration End</label></div>
                                                <div className="col-md-6">
                                                    <input type="date"
                                                           defaultValue={tournament.registrationEndDate && moment(tournament.registrationEndDate).format('yyyy-MM-DD')}
                                                           className="form-control"
                                                           placeholder="Date"
                                                           onBlur={(event) => {
                                                               doRestCall('/tournament/update/regenddate', 'put', null, {
                                                                   tournamentId: tournament.id,
                                                                   regEndDate: event.target.value
                                                               })
                                                           }}/>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="t-e-mb-3">
                                        <div className="row">
                                            <div className="col-md-3"><label className="form-label">Universe</label></div>
                                            <div className="col-md-9">
                                                <Universes
                                                    tournamentId={tournament.id}
                                                    setSelectedUniverse={setSelectedUniverse}
                                                    preSelectedUniverse={tournament.universe}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="t-e-mb-3">
                                        <div className="row">
                                            <div className="col-md-3"><label className="form-label">Tournament Master</label></div>
                                            <div className="col-md-9">
                                                <Master form={form} tournamentId={tournamentId} master={tournament.tournamentMasterId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <div className="row">
                                            <div className="col-md-3"><label className="form-label">Participants Limit</label></div>
                                            <div className="col-md-9">
                                                <input type="text" placeholder="Limit"
                                                          defaultValue={tournament.participantsLimit}
                                                          onBlur={(data) => {
                                                              doRestCall('/tournament/update/participantslimit', 'put', null, {
                                                                  tournamentId: tournament.id,
                                                                  participantsLimit: data.target.value
                                                              })
                                                          }}
                                                ></input>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-5">
                                        <div className="row">
                                            <div className="col-md-3"><label className="form-label">Notes</label></div>
                                            <div className="col-md-9">
                                                <textarea name="" id="" className="form-control" rows="4"
                                                          defaultValue={tournament.notes}
                                                          onBlur={(data) => {
                                                              doRestCall('/tournament/update/notes', 'put', null, {
                                                                  tournamentId: tournament.id,
                                                                  notes: data.target.value
                                                              })
                                                          }}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>


                                    <Participants tournamentId={tournament.id}
                                                  tournamentPlayers={tournament.players}
                                                  selectedUniverse={selectedUniverse} />



                                    <Rounds
                                        tournamentId={tournamentId}
                                        rounds={tournament.rounds}
                                        setErrors={setErrors}
                                        setTournament={setTournament}/>


{/*
                                    <div className="t-e-mb-3">
                                        <table className="t-e-vtable">
                                            <thead className="t-e-vtable-head">
                                            <tr className="t-e-vtable-row">
                                                <th className="t-e-vtable-header"></th>
                                                <th className="t-e-vtable-header">Name</th>
                                                <th className="t-e-vtable-header"></th>
                                            </tr>
                                            </thead>
                                            <tbody className="vtable-body">
                                            <tr className="vtable-row">
                                                <td className="t-e-vtable-cell">
                                                    <i className="fa-regular fa-square"></i>
                                                </td>
                                                <td className="t-e-vtable-cell">Lorem ipsum.</td>
                                                <td className="t-e-vtable-cell">
                                                    <select name="" id="">
                                                        <option value="">faction select</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr className="t-e-vtable-row">
                                                <td className="t-e-vtable-cell">
                                                    <i className="fa-regular fa-square"></i>
                                                </td>
                                                <td className="t-e-vtable-cell">Lorem ipsum.</td>
                                                <td className="t-e-vtable-cell">
                                                    <select name="" id="">
                                                        <option value="">faction select</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr className="t-e-vtable-row">
                                                <td className="t-e-vtable-cell">
                                                    <i className="fa-regular fa-square"></i>
                                                </td>
                                                <td className="t-e-vtable-cell">Lorem ipsum.</td>
                                                <td className="t-e-vtable-cell">
                                                    <select name="" id="">
                                                        <option value="">faction select</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr className="t-e-vtable-row">
                                                <td className="t-e-vtable-cell">
                                                    <i className="fa-regular fa-square"></i>
                                                </td>
                                                <td className="vtable-cell">Lorem ipsum.</td>
                                                <td className="vtable-cell">
                                                    <select name="" id="">
                                                        <option value="">faction select</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
*/}
                                </div>
                                <div className="row d-flex justify-content-between">
                                    <div className="col-auto">
                                        <div className="t-e-tlist-btn" onClick={() => navigateBack()}>Back</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*

            <h2 className="tournEditTitle">Edit Tournament {tournament.name}</h2>

            <Errors errors={errors} />

            <Form
                id={"form"}
                onFinish={onFinish}
                form={form}
            >

                <Space className="tournEditForm" direction={"vertical"}>
                    <Form.Item
                        className="tournEditLabel"
                        id="id"
                        hidden="true"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        className="tournEditLabel"
                        id="status"
                        hidden="true"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        className="tournEditLabel"
                        id="createdDateTime"
                        hidden="true"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        className="tournEditLabel"
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input name!',
                            },
                        ]}>
                        <Input className="tournEditInput"
                               onChange={(data) => {
                                   console.log(data.target.value)
                            doRestCall('/tournament/update/name', 'put', null, {
                                tournamentId: tournament.id,
                                name: data.target.value
                            })
                        }}
                        />

                    </Form.Item>

                    <Space direction={"horizontal"}>
                        <Space className="tournEditDatesLeft" direction={"vertical"}>
                            <Form.Item
                                className="tournEditLabelLong"
                                label="Start Date"
                                name="startDate">

                                <DatePicker className="tournEditDate"
                                            onChange={(data) => {
                                                doRestCall('/tournament/update/startdate', 'put', null, {
                                                    tournamentId: tournament.id,
                                                    startDate: data
                                                })
                                            }}/>

                            </Form.Item>

                            <Form.Item
                                className="tournEditLabelLong tournEditNoMarginBottom"
                                label="End Date"
                                name="endDate">

                                <DatePicker className="tournEditDate"
                                            onChange={(data) => {
                                                doRestCall('/tournament/update/enddate', 'put', null, {
                                                    tournamentId: tournament.id,
                                                    endDate: data
                                                })
                                            }}/>

                            </Form.Item>

                        </Space>
                        <Space className="tournEditDatesRight" direction={"vertical"}>
                            <Form.Item

                                label="Registration Start Date"
                                name="registrationStartDate">
                                <DatePicker className="tournEditDate"
                                            onChange={(data) => {
                                                doRestCall('/tournament/update/regstartdate', 'put', null, {
                                                    tournamentId: tournament.id,
                                                    regStartDate: data
                                                })
                                            }}/>

                            </Form.Item>

                            <Form.Item

                                label="Registration End Date"
                                name="registrationEndDate">
                                <DatePicker className="tournEditDate"
                                            onChange={(data) => {
                                                doRestCall('/tournament/update/regenddate', 'put', null, {
                                                    tournamentId: tournament.id,
                                                    regEndDate: data
                                                })
                                            }}/>
                            </Form.Item>
                        </Space>
                    </Space>

                    <Form.Item
                        className="tournEditLabel"
                        label="Universe"
                        name="universe"
                        style={{width: '10.3rem', justifyContent: 'start', display: 'flex'}}
                    >
                        <Universes
                            tournamentId={tournament.id}
                            setSelectedUniverse={setSelectedUniverse}
                            preSelectedUniverse={tournament.universe}
                        />
                    </Form.Item>

                    <Master form={form} tournamentId={tournamentId} master={tournament.tournamentMasterId} />

                    <Space direction={"horizontal"} >
                        <Text>Participants Limit</Text>
                    <InputNumber min={0} value={participantLimit} onChange={(value) => {
                        setParticipantLimit(value)
                        doRestCall('/tournament/update/participantslimit', 'put', null, {
                            tournamentId: tournament.id,
                            participantsLimit: value
                        })
                    }} />
                    </Space>

                    <Space direction={"horizontal"}>
                        <p>Location</p>
                        <TextArea rows={3} maxLength={1000}
                                  onBlur={(data) => {
                                      doRestCall('/tournament/update/location', 'put', null, {
                                          tournamentId: tournament.id,
                                          address: data.target.value
                                      })
                                  }}/>
                    </Space>

                    <Space direction={"horizontal"}>
                        <p>Notes</p>
                        <TextArea rows={6} maxLength={1000}
                                  onBlur={(data) => {
                                      doRestCall('/tournament/update/notes', 'put', null, {
                                          tournamentId: tournament.id,
                                          notes: data.target.value
                                      })
                                  }}
                        />
                    </Space>
                </Space>

                <Form.Item name="gameIds" hidden={true} />
                <Form.Item name="userIds" hidden={true} />
                <Form.Item name="users" hidden={true} />

                <Participants tournamentId={tournament.id}
                              tournamentPlayers={tournament.players}
                              selectedUniverse={selectedUniverse} />

                <Rounds
                    tournamentId={tournamentId}
                    rounds={tournament.rounds}
                    setErrors={setErrors}
                    setTournament={setTournament}/>

                <Settings tournamentId={tournamentId} />

                <Form.Item>
                    <Space className="tournEditButtons" direction="horizontal">
                        <Button onClick={navigateBack}>Back</Button>
                    </Space>
                </Form.Item>

            </Form>
*/}
        </>
    );
};

export default TournamentEdit;

