import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import {Button, Form, Input, Modal, Popconfirm, Select, Space, Spin, Table, Tabs} from "antd";
import {doRestCall} from "../../../AppUtils";
import Winner from "./Winner";
import moment from "moment";
import {DeleteOutlined} from "@ant-design/icons";
import Link from "antd/lib/typography/Link";
import ParticipantCheck from "../../view/components/ParticipantCheck";

const Rounds = ({ rounds, tournamentId, setErrors, setTournament }) => {

    const navigate = useNavigate();

    const [roundItems, setRoundItems] = useState([]);
    const [selectedRound, setSelectedRound] = useState();
    const [isCurrentRoundLastOne, setIsCurrentRoundLastOne] = useState(selectedRound === rounds?.length)
    const [showCreateNewGamePopup, setShowCreateNewGamePopup] = useState(false)

    const [name, setName] = useState("")



    const [games, setGames] = useState([])
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false)


    function convertRounds(rounds) {
        let temp = []
        for (let i = 0; i < rounds?.length; i++) {
            temp[i] = {
                key: rounds[i].num,
                label: 'Round ' + rounds[i].num,
                children: <Spin tip="Loading" size="large" spinning={loading} >
                    {(games?.length > 0) &&
                        <Table
                            columns={gamesTableColumns}
                            dataSource={items}
                            showHeader={true}
                        />
                    }
                </Spin>
            }
        }
        setRoundItems(temp)
    }

    useEffect(() => {
        convertRounds(rounds);
    }, [rounds, items])

    useEffect(() => {
        setSelectedRound(rounds?.length)
    }, [rounds])

    useEffect(() => {
        setIsCurrentRoundLastOne(selectedRound === rounds?.length)
    }, [selectedRound])

    function generateNewRound() {
        doRestCall('/tournament/' + tournamentId + '/nextround', 'post', null, null,
            (response) => {
                if (!response.valid) {
                    setErrors(response.errors)
                }
                if (response.valid) {
                    reloadTournament()
                }
            })
    }

    function generateNewEmptyRound() {
        doRestCall('/tournament/' + tournamentId + '/nextemptyround', 'post', null, null,
            (response) => {
                if (!response.valid) {
                    setErrors(response.errors)
                }
                if (response.valid) {
                    convertRounds(response.body.rounds);
                }
            })
    }

    function reloadTournament() {
        doRestCall('/tournament/view/' + tournamentId, 'get', null, null,
            (response) => setTournament(response.body));
    }

    function deleteRound() {
        doRestCall('/tournament/' + tournamentId + '/round/' + selectedRound + '/delete', 'delete', null, null,
            () => reloadTournament());
    }

    const buttons = {
        right: <Space direction={"horizontal"}>
            <Button onClick={() => setShowCreateNewGamePopup(true)}>Create New Game</Button>
            <Button disabled={true}>Add Existing Game</Button>
            <Button onClick={() => generateNewEmptyRound()}>Create Empty Round</Button>
            <Button onClick={() => generateNewRound()}>Generate New Round</Button>
            <Button disabled={!isCurrentRoundLastOne} onClick={() => deleteRound()}>Delete Round</Button>
        </Space>
    };

    /* games */

    useEffect(() => {
        const temp = [];
        for (let i = 0; i < games?.length; i++) {
            temp[i] = {
                key: games[i].key,
                id: games[i].id,
                name: games[i].name,
                player1: games[i].players[0]?.username,
                player2: games[i].players[1]?.username,
                date: games[i].dateTime,
                players: games[i].players,
                winner: games[i].gameWinnerUsername
            }
        }
        setItems(temp)

    }, [games]);

    useEffect(() => {
        if (selectedRound !== null && selectedRound !== undefined) {
            loadGames()
        }
    }, [selectedRound])

    function loadGames() {
        setLoading(true)
        doRestCall('/tournament/' + tournamentId + '/round/' + selectedRound + '/games', 'get', null, null,
            (response) => setGames(response), null,
            () => setLoading(false));
    }

    function deleteGame(gameId) {
        doRestCall('/tournament/' + tournamentId + '/game/' + gameId + '/delete', 'delete', null, null,
            () => loadGames())
    }

    const gamesTableColumns = [{
        title: 'Name', dataIndex: 'name', key: 'key', width: '30%',
        render: (_, row) => <>
            <Link onClick={() => navigate('/game/view/' + row.id)}>
                {row.name}
            </Link>
        </>
    }, {
        title: 'Player1', dataIndex: 'player1', key: 'player1', width: '20%',
    }, {
        title: 'Player2', dataIndex: 'player2', key: 'player2', width: '20%',
    }, {
        title: 'Winner', dataIndex: 'winner', key: 'winner', width: '20%',
        render: (_, row) =>  <>
            <Winner players={row.players} winner={row.winner} gameId={row.id}/>
        </>
    }, {
        title: 'Date', dataIndex: 'date', key: 'date', width: '20%',
        render: (_, row) =>  <p>
            {row.date && moment(row.date, 'YYYY-MM-DDThh:mm:ss').format('DD MMM YYYY')}
        </p>
    }, {
        title: '', dataIndex: '', key: '',
        render: (_, row) => <DeleteOutlined onClick={() => {
            deleteGame(row.id)
        }}/>
    }
    ];

    /* games */


    /* modal */

    const handleOk = () => {
        doRestCall('/tournament/' + tournamentId + '/round/' + selectedRound + '/game/create', 'post',
            null, {
                name: name
            },
            () => {
                loadGames()

                setName("")
                setShowCreateNewGamePopup(false)
            })
    };
    const handleCancel = () => {
        setName("")
        setShowCreateNewGamePopup(false)
    };

    const onNameChange = (e) => {
        setName(e.target.value)
    }

    /* modal */

    return <>
        <div className="t-e-vtable1-main">
            <div className="t-e-vtable-container">
                <h2 className="t-e-vtable-title2">Rounds</h2>
                <div className="t-e-rounds3">
                    <div className="t-e-round3-iner">
                        {rounds?.map(round =>
                            <button
                                onClick={() => setSelectedRound(round.num)}
                                className={round.num === selectedRound ? "t-e-tlist-btn-sm-active" : "t-e-tlist-btn-sm"}>Round {round.num}</button>
                            )}
                    </div>
                    <div className="">
                        <button className="t-e-tlist-btn-sm" onClick={() => setShowCreateNewGamePopup(true)}>Create New Game</button>
                        <button className="t-e-tlist-btn-sm" disabled={true}>Add Existing Game</button>
                        <button className="t-e-tlist-btn-sm" onClick={() => generateNewEmptyRound()}>Create Empty Round</button>
                        <button className="t-e-tlist-btn-sm" onClick={() => generateNewRound()}>Generate New Round</button>
                        <button className="t-e-tlist-btn-sm" disabled={!isCurrentRoundLastOne} onClick={() => deleteRound()}>Delete Round</button>
                    </div>
                    </div>
                    <table className="t-e-vtable">
                        <thead className="t-e-vtable-head">
                        <tr className="t-e-vtable-row">
                            <th className="t-e-vtable-header">Name</th>
                            <th className="t-e-vtable-header">Player1</th>
                            <th className="t-e-vtable-header">Player2</th>
                            <th className="t-e-vtable-header">Winner</th>
                            <th className="t-e-vtable-header">Date</th>
                            <th className="t-e-vtable-header"></th>
                        </tr>
                        </thead>
                        <tbody className="t-e-vtable-body">
                        {items?.map(item =>
                            <tr className="t-e-vtable-row">
                                <td className="t-e-vtable-cell">{item.name}</td>
                                <td className="t-e-vtable-cell">{item.player1}</td>
                                <td className="t-e-vtable-cell">{item.player2}</td>
                                <td className="t-e-vtable-cell"><Winner players={item.players} winner={item.winner}
                                                                        gameId={item.id}/></td>
                                <td className="t-e-vtable-cell">{item.date && moment(item.date, 'YYYY-MM-DDThh:mm:ss').format('DD MMM YYYY')}</td>
                                <td className="t-e-vtable-cell"><i className="fa-solid fa-trash"
                                                                   onClick={() => deleteGame(item.id)}></i>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/*
        Rounds

        <Modal title="Create New Game" open={showCreateNewGamePopup} onOk={handleOk} onCancel={handleCancel}>
            <Space direction={"horizontal"}>
                New Game Name: <Input onChange={onNameChange} />
            </Space>
        </Modal>

        <Tabs
            onChange={(round) => setSelectedRound(round)}
            tabBarExtraContent={buttons}
            defaultActiveKey={rounds?.length}
            items={roundItems}
        />
*/}
    </>
}

export default Rounds;