import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Modal, Space} from "antd";
import {doRestCall} from "../../../../AppUtils";

const CreateRosterButton = ({forceId, ownerId}) => {

    const {currentUser} = useCurrentUserContext();
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [newRosterError, setNewRosterError] = useState()
    const [newRosterName, setNewRosterName] = useState({})

    function onOk() {

        if (newRosterName === null || newRosterName === undefined || newRosterName === "") {
            setNewRosterName("Name is required")
            return
        }

        doRestCall('/crusade/force/' + forceId + '/roster/create', 'post', null, {
            name: newRosterName
        }, (response) => {
            setNewRosterName("")
            setNewRosterError("")
            setModalOpen(false)
            navigate('/crusade/force/' + forceId +'/roster/' + response.body.id + '/manage')
        })

    }

    function onCancel() {
        setNewRosterName("")
        setNewRosterError("")
        setModalOpen(false)
    }

    return <>
        <Modal title="Create Roster" open={modalOpen} onOk={() => onOk()} onCancel={() => onCancel()}>
            {newRosterError && <p>{newRosterError}</p>}
            <Space direction="horizontal">
                <label>Name</label>
                <input type="text" placeholder="Name" onChange={(e) => setNewRosterName(e.target.value)}/>
            </Space>
        </Modal>

        {(currentUser?.role === 'ADMIN' || currentUser?.id === ownerId)
            && <button data-cy="manage" className="c-f-view-tlist-btn" onClick={() => {setModalOpen(true)}}>Create Roster</button>}
    </>
}

export default CreateRosterButton;