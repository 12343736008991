import React from "react";
import "../styles-h.css";
import moment from "moment/moment";

const DefaultNewsItem = ({imageUrl, url, title, publishedDate}) => {

    function openLink(link) {
        window.open(link, '_blank').focus();
    }

    return (
        <>
            <div className="h-card-2">
                {!imageUrl.endsWith('/null') && <img src={imageUrl} /> }
                <div className="h-card-content">
                    <a onClick={() => openLink(url)}><h3 className="h-card-title">{title}</h3></a>
                    <p className="h-card-description">{moment(publishedDate, 'YYYYMMDDhhmm').format('DD-MMM-YYYY')}</p>
                </div>
            </div>

        </>
    );
};

export default DefaultNewsItem;