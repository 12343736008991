import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../../AppUtils";
import WarlordMark from "./WarlordMark";

const CardPrint = ({card}) => {

    const [battleScars, setBattleScars] = useState([])
    const [battleTraits, setBattleTraits] = useState([])
    const [weaponModifications, setWeaponModifications] = useState([])
    const [relics, setRelics] = useState([])


    useEffect(() => {
        doRestCall('/codex/crusade/battlescars', 'get', null, null,
            (response) => setBattleScars(response.body))
    }, [card])

    useEffect(() => {
        if (card !== undefined && card !== null &&
            card.keywords !== null && card.keywords !== undefined && card.keywords.length > 0) {

            doRestCall('/codex/crusade/battletraits', 'get', {
                    keywords: card.keywords
                }, null,
                (response) => setBattleTraits(response.body))
        }
    }, [card])

    useEffect(() => {
        if (card !== undefined && card !== null &&
            card.keywords !== null && card.keywords !== undefined && card.keywords.length > 0) {

            doRestCall('/codex/crusade/weaponmodifications', 'get', {
                    keywords: card.keywords
                }, null,
                (response) => setWeaponModifications(response.body))
        }
    }, [card])

    useEffect(() => {
        if (card !== undefined && card !== null &&
            card.keywords !== null && card.keywords !== undefined && card.keywords.length > 0) {

            doRestCall('/codex/crusade/relics', 'get', {
                    keywords: card.keywords
                }, null,
                (response) => setRelics(response.body))
        }
    }, [card])


    return <>
        <div className="row">
            <div className="col">
                <p><WarlordMark warlord={card.warlord}/>  {card.name}</p>
                <p>{card.keywords}</p>
                <p>{card.equipments}</p>
                <p>{card.enhancements}</p>
            </div>
            <div className="col">
                <p>Battle Traits</p>
                {(card.battleTraits === null || card.battleTraits.length === 0) && <p>None</p>}
                {battleTraits && card?.battleTraits?.map(cardBattleTraits => {
                    let fbs = battleTraits.filter(bs => bs.key === cardBattleTraits);
                    if (fbs !== null && fbs !== undefined && fbs.length > 0) {
                        return <p className="print-short">{fbs[0].label}: {fbs[0].description}</p>
                    } else {
                        return <p>None</p>
                    }
                })}
                <p>Weapon Modifications</p>
                {(card.weaponModifications === null || card.weaponModifications.length === 0) && <p>None</p>}
                {weaponModifications && card?.weaponModifications?.map(cardWeaponModifications => {
                    let fbs = weaponModifications.filter(bs => bs.key === cardWeaponModifications);
                    if (fbs !== null && fbs !== undefined && fbs.length > 0) {
                        return <p className="print-short">{fbs[0].label}: {fbs[0].description}</p>
                    } else {
                        return <p>None</p>
                    }
                })}
                <p>Relics</p>
                {(card.relics === null || card.relics.length === 0) && <p>None</p>}
                {relics && card?.relics?.map(cardRelics => {
                    let fbs = relics.filter(bs => bs.key === cardRelics);
                    if (fbs !== null && fbs !== undefined && fbs.length > 0) {
                        return <p className="print-short">{fbs[0].label}: {fbs[0].description}</p>
                    } else {
                        return <p>None</p>
                    }
                })}
                <p>Battle Scars</p>
                {(card.battleScars === null || card.battleScars.length === 0) && <p>None</p>}
                {battleScars && card?.battleScars?.map(cardBattleScar => {
                    let fbs = battleScars.filter(bs => bs.key === cardBattleScar);
                    if (fbs !== null && fbs !== undefined && fbs.length > 0) {
                        return <p className="print-short">{fbs[0].label}: {fbs[0].description}</p>
                    } else {
                        return <p>None</p>
                    }
                })}
                <br/>
                <div className="container">
                    <div className="row">
                        <div className="col-10">Enemy Units Destroyed
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col-10">Enemy Units Destroyed with Psychic Power
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            Enemy Units Destroyed with Ranged Weapon
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            Enemy Units Destroyed with Melee Weapon
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            Agenda #1 Tally...
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            Agenda #2 Tally...
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            Agenda #3 Tally...
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
            </div>
        </div>

    </>
};

export default CardPrint;

