import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {
    updateBattleTally,
    updateName, updateReqPoints,
    updateSupplyLimit,
    updateSupplyUsed,
    updateVictories
} from "../ForceConnector";
import {Auth} from "../../../user/auth/Auth";
import {notificationFailure, notificationTypeUpdateSuccess} from "../../card/CardNotifications";
import {
    notificationBattleTallyUpdateSuccess,
    notificationNameUpdateSuccess, notificationNotesUpdateSuccess,
    notificationReqPointsUpdateSuccess, notificationSupplyLimitUpdateSuccess, notificationSupplyUsedUpdateSuccess,
    notificationVictoriesUpdateSuccess
} from "../ForceNotifications";
import {GoogleAnalyticsPageView} from "../../../GoogleAnalytics";
import {doRestCall} from "../../../AppUtils";

import "./c-f-manage-styles.css"
import {Modal, Space} from "antd";
import Cards from "./components/Cards";
import Rosters from "./components/Rosters";

const ForceManage = () => {

    GoogleAnalyticsPageView("/crusade/force/manage", "Crusade Force Manage")

    const navigate = useNavigate();

    let {forceId} = useParams();

    const [force, setForce] = useState({});



    useEffect(() => {
        loadForce()
    }, [])

    function onSuccessView(response) {
        setForce(response.body)
    }

    const loadForce = () => {
        doRestCall( '/crusade/force/' + forceId + '/view', 'get', null, null, onSuccessView )
    }


    const onAddUnit = (e) => {
        e.preventDefault()
        navigate("/crusade/force/" + force.id + "/unit/create")
    };

    function navigateBack(e) {
        e.preventDefault()
        navigate("/crusade/force/" + forceId + "/view")
    }


    const onNameUpdate = (e) => {
        updateName(forceId, e.target.value, notificationNameUpdateSuccess, notificationFailure)
    }

    const onReqPointsUpdate = (e) => {
        updateReqPoints(forceId, e.target.value, notificationReqPointsUpdateSuccess, notificationFailure)
    }
    const onVictoriesUpdate = (e) => {
        updateVictories(forceId, e.target.value, notificationVictoriesUpdateSuccess, notificationFailure)
    }
    const onBattleTallyUpdate = (e) => {
        updateBattleTally(forceId, e.target.value, notificationBattleTallyUpdateSuccess, notificationFailure)
    }
    const onSupplyUsedUpdate = (e) => {
        updateSupplyUsed(forceId, e.target.value, notificationSupplyUsedUpdateSuccess, notificationFailure)
    }
    const onSupplyLimitUpdate = (e) => {
        updateSupplyLimit(forceId, e.target.value, notificationSupplyLimitUpdateSuccess, notificationFailure)
    }
    const onNotesUpdate = (e) => {
        doRestCall( '/crusade/force/update/notes', 'put',{
            forceId: forceId,
            notes: e.target.value
        }, null, notificationNotesUpdateSuccess, notificationFailure)
    }
    return (
        <>
            <Auth/>

            <div className="c-f-manage-create-game-section mt-5">
                <div className="c-f-manage-create-game-container">
                    <div className="c-f-manage-create-game-header mb-5">
                        <h2>{force.name}</h2>
                    </div>
                    <div className="c-f-manage-create-game-form">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-10">
                                <div className="form mb-5">
                                    <div className="mb-3">
                                        <div className="col-md-6">
                                            <label>Name</label>
                                            <input type="text" placeholder="Name" defaultValue={force.name}
                                                   onBlur={(e) => onNameUpdate(e)}/>
                                        </div>
                                    </div>
                                    <div className="row mb-5 d-flex justify-content-between">
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-manage-text-center">Supply
                                                    Limit</label>
                                                <input type="number" min={0} defaultValue={force.supplyLimit} onBlur={(e) => {
                                                    onSupplyLimitUpdate(e)
                                                }}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-manage-text-center">Supply Used</label>
                                                <input type="number" min={0} defaultValue={force.supplyUsed} onBlur={(e) => {
                                                    onSupplyUsedUpdate(e)
                                                }}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-manage-text-center">Battle
                                                    Tally</label>
                                                <input type="number" min={0} defaultValue={force.battleTally} onBlur={(e) => {
                                                    onBattleTallyUpdate(e)
                                                }}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-manage-text-center">Victories</label>
                                                <input type="number" min={0} defaultValue={force.victories} onBlur={(e) => {
                                                    onVictoriesUpdate(e)
                                                }}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-manage-text-center">Requisition
                                                    Points</label>
                                                <input type="number" min={0} defaultValue={force.reqPoints} onBlur={(e) => {
                                                    onReqPointsUpdate(e)
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <Cards forceId={forceId} />
                                    <div className="mb-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="form-label">Notes</label>
                                                <textarea
                                                    defaultValue={force.notes}
                                                    name=""
                                                    id=""
                                                    className=""
                                                    rows="4" onBlur={(e) => onNotesUpdate(e)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <Rosters forceId={forceId} />
                                </div>
                                <div className="row d-flex justify-content-between">
                                    <div className="col-auto">
                                        <div className="c-f-manage-tlist-btn" onClick={(e) => navigateBack(e)}>Back
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default ForceManage;

