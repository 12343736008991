import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Modal, Space} from "antd";
import {doRestCall} from "../../../../AppUtils";

const Cards = ({forceId}) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [force, setForce] = useState({});
    const [cards, setCards] = useState([]);


    const [cardModalOpen, setCardModalOpen] = useState(false);
    const [newCardError, setNewCardError] = useState()
    const [newCardName, setNewCardName] = useState({})

    useEffect(() => {
        loadForce()
    }, [])

    function onSuccessView(response) {
        setForce(response.body)
        setCards(response.body.cards)
        setLoading(false)
    }

    const loadForce = () => {
        doRestCall( '/crusade/force/' + forceId + '/view', 'get', null, null, onSuccessView )
    }

    function onOk() {
        if (newCardName === null || newCardName === undefined || newCardName === "") {
            setNewCardError("Name is required")
            return
        }

        doRestCall('/crusade/card/create', 'post', null, {
            forceId: forceId,
            name: newCardName
        }, (response) => {
            setNewCardName("")
            setNewCardError("")
            setCardModalOpen(false)
            navigate("/crusade/force/" + force.id + "/card/" + response.body.id + "/manage")
        })
    }

    function onCancel() {
        setNewCardName("")
        setNewCardError("")
        setCardModalOpen(false)
    }

    const onManageUnit = (e, cardId) => {
        e.preventDefault()
        navigate("/crusade/force/" + force.id + "/card/" + cardId + "/manage")
    };

    const onCardDelete = (e, forceId, cardId) => {
        e.preventDefault()
        doRestCall( '/crusade/force/' + forceId + '/card/' + cardId + '/delete', 'delete', null, null,
            () => loadForce())
    }

    return <>
        <div className="mb-3">
            <h4>Cards</h4>
        </div>
        <div className="float-end mb-3">
            <div className="c-f-manage-tlist-btn" onClick={(e) => {
                setCardModalOpen(true)
            }}>Create Card
            </div>
        </div>
        <div className="mb-3">
            <table className="c-f-manage-vtable">
                <thead className="c-f-manage-vtable-head">
                <tr className="c-f-manage-vtable-row">
                    <th className="c-f-manage-vtable-header">Name</th>
                    <th className="c-f-manage-vtable-header">Points</th>
                    <th className="c-f-manage-vtable-header">Crusade Points</th>
                    <th className="c-f-manage-vtable-header"></th>
                </tr>
                </thead>
                <tbody className="c-f-manage-vtable-body">
                {force?.cards?.map(card =>
                    <tr className="c-f-manage-vtable-row">
                        <td className="c-f-manage-vtable-cell c-f-manage-vtable-cell-name">{card.name}</td>
                        <td className="c-f-manage-vtable-cell c-f-manage-vtable-cell-points">{card.points}</td>
                        <td className="c-f-manage-vtable-cell c-f-manage-vtable-cell-c-points">{card.crusadePoints}</td>
                        <td className="c-f-manage-vtable-cell c-f-manage-vtable-cell-actions">
                            <i className="fa-solid fa-pen c-f-manage-vtable-cell-action-icon"
                               onClick={(e) => onManageUnit(e, card.id)}></i>
                            <i className="fa-solid fa-trash c-f-manage-vtable-cell-action-icon"
                               onClick={(e) => onCardDelete(e, card.forceId, card.id)}></i>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

        <Modal title="Create Card" open={cardModalOpen} onOk={() => onOk()} onCancel={() => onCancel()}>
            {newCardError && <p>{newCardError}</p>}
            <Space direction="horizontal">
                <label>Name</label>
                <input type="text" placeholder="Name" onChange={(e) => setNewCardName(e.target.value)}/>
            </Space>
        </Modal>
    </>
}

export default Cards;