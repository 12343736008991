import React from 'react';
import { Children } from 'react';

const Spinner = ({isLoading, children}) => {

    return <>
        {isLoading && <div className="spinner"></div>}
        {!isLoading && children}
    </>
}

export default Spinner;