import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {doRestCall} from "../AppUtils";
import {Spin} from "antd";


const StripePrice = ({id, name, amount, subscriptionSelected}) => {

    const [isSubscribed, setIsSubscribed] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        doRestCall('/stripe/subscription/status/' + id, 'get', null, null,
            (response) => {
                setIsSubscribed(response.body.status === 'active')
            },
            null,
            () => setLoading(false))
    }, [])


    return (
        <div key={id}>
            <h3>{name}</h3>
            <p>
                ${amount / 100} / month
            </p>

            <Spin spinning={loading}>
            {isSubscribed && <p>Already Subscribed</p>}
            {!isSubscribed && <button onClick={() => subscriptionSelected(id)}>
                Subscribe
            </button>
            }
            </Spin>
        </div>
    )
}

export default StripePrice;
