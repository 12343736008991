import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {doRestCall} from "../AppUtils";
import StripePrice from "./StripePrice";


const StripePrices = ({subscriptionSelected}) => {
    const [prices, setPrices] = useState([]);

    useEffect(() => {
        doRestCall('/stripe/prices', 'get', null, null,
            (response) => {
                setPrices(response.body.prices)
            })
    }, [])

    return (
        <div>
            <h1>Select a plan</h1>

            <div className="price-list">
                {prices.map((price) => {
                    return <StripePrice id={price.id}
                                 name={price.name}
                                 amount={price.amount}
                                 subscriptionSelected={subscriptionSelected}
                    />
                })}
            </div>
        </div>
    )
}

export default StripePrices;
